import store from "../../Redux/store";
import {
  UpdateCookiebarStatePayload,
  updateCookiebarState,
  CookiebarState,
} from "../../Redux/reducer/user/slice";

export const devour_client_cookiebar_middleware = {
  name: "cookiebar",
  res: function (payload: any) {
    if (
      !payload.res.data.meta ||
      payload.res.data.meta.cookiebar_state === undefined
    ) {
      return payload;
    }

    let updateCookiebarStatePayload = new UpdateCookiebarStatePayload(
      payload.res.data.meta.cookiebar_state === null
        ? CookiebarState.Undefined
        : parseInt(payload.res.data.meta.cookiebar_state)
    );

    store.dispatch(updateCookiebarState(updateCookiebarStatePayload));

    return payload;
  },
};
