import React from "react";
import store from "../Redux/store";
import {
  Company,
  companyEntityAdapter,
  CompanysLoadingStatus,
  fetchCompanies,
  setCurrentCompany,
  SetCurrentCompanyPayload,
} from "../Redux/reducer/company/slice";
import {
  fetchLocations,
  Location,
  locationEntityAdapter,
  LocationsLoadingStatus,
  setCurrentLocation,
  SetCurrentLocationPayload,
} from "../Redux/reducer/location/slice";
import { dispatchUserActivityLoginEvent } from "../Redux/reducer/user/slice";
import AlertDialog from "../Component/Popup/AlertDialog";
import { getNumberOfPendingRequests } from "../Client/axios/pendingRequests";
import eventBus from "./eventBus";
import Cookies from "universal-cookie";
import moment from "moment";

let lastUserIamId: string = "",
  lastCurrentCompanyId: string = "",
  lastCurrentLocationId: string = "",
  lastCompanyLoadingStatus: CompanysLoadingStatus = CompanysLoadingStatus.Idle,
  lastLocationLoadingStatus: LocationsLoadingStatus =
    LocationsLoadingStatus.Idle;

const cookies = new Cookies(),
  cookie_previous_company_id_key = "preloadOnUserChange-company",
  cookie_previous_location_id_key = "preloadOnUserChange-location",
  cookie_shown_welcome_key = "preloadOnUserChange-welcome";

/**
 * MS-454: When an user first logges in the Drupal backend creates a new user.
 * At login almost simultaneously calls to /user-activity/login (to register
 * the login event) and to /jsonapi/company/company (to load all companies for
 * the current user) are done. This leads to an internal server error because
 * Drupal has two requests in which it is creating the new user.
 *
 * This function solves the problem by waiting till there are no running
 * requests left before requestion /jsonapi/company/company.
 *
 */
function fetchCompaniesWhenThereAreNoPendingRequests() {
  if (getNumberOfPendingRequests() > 0) {
    setTimeout(fetchCompaniesWhenThereAreNoPendingRequests, 250);
    return;
  }
  store.dispatch(fetchCompanies());
}

/**
 * This makes sure all required data is loaded after an user login/change has occured.
 */
function preloadOnUserChange() {
  let state = store.getState(),
    currentUser = state.user.user ? state.user.user : undefined,
    currentCompany = state.company.currentCompany,
    currentLocation = state.location.currentLocation;

  if (
    (lastUserIamId !== "" && !currentUser) ||
    (currentUser && lastUserIamId !== currentUser.iamId)
  ) {
    lastUserIamId = currentUser ? currentUser.iamId : "";

    // Wait a bit to make sure the request to /user-activity/login is running.
    setTimeout(fetchCompaniesWhenThereAreNoPendingRequests, 250);
  }

  if (
    lastCompanyLoadingStatus !== state.company.status &&
    state.company.status === CompanysLoadingStatus.Succeeded &&
    !currentCompany
  ) {
    // No current company selected, and all companies are loaded.
    let previous_company: Company | undefined,
      cookie_previous_company_id = cookies.get(cookie_previous_company_id_key);

    if (
      cookie_previous_company_id &&
      (previous_company = companyEntityAdapter
        .getSelectors()
        .selectById(state.company, cookie_previous_company_id))
    ) {
      setTimeout(function () {
        store.dispatch(
          setCurrentCompany(new SetCurrentCompanyPayload(previous_company))
        );
      }, 100);
    } else {
      eventBus.dispatch("RequestCompanyChange", { allowClose: false });
    }
  }

  lastCompanyLoadingStatus = state.company.status;

  if (
    (lastCurrentCompanyId !== "" && !currentCompany) ||
    (currentCompany && lastCurrentCompanyId !== currentCompany.id)
  ) {
    lastCurrentCompanyId = currentCompany ? currentCompany.id : "";

    if (currentCompany && !currentCompany.email) {
      // Company contact email is empty, it seems the user didn't agree
      // with the RIE terms. See MS-347 / STIG-1084.
      AlertDialog.show(
        <div>
          <p>
            Je hebt je recentelijk aangemeld voor de RIE van Stigas. Goed werk,
            want met een goed opgestelde RIE houdt je jouw organisatie veilig en
            vitaal!
          </p>
          <p>
            Met het aanvragen van de RIE ontvang je automatisch toegang tot Mijn
            Stigas: hét nieuwe platform waar je alles in kaart kan brengen om
            jouw organisatie veilig en vitaal te houden en om verzuim te
            voorkomen.
          </p>
          <p>
            Via onderstaande button ga je direct door naar de RIE van Stigas. We
            zien je hopelijk snel terug in Mijn Stigas!
          </p>
          <p>
            <a href={currentCompany.mijnStigasApiEditLink}>
              Klik hier om te gaan naar de RIE van Stigas.
            </a>
          </p>
        </div>,
        undefined,
        "Welkom bij Mijn Stigas!",
        "",
        true
      );
      return;
    }
    lastLocationLoadingStatus = LocationsLoadingStatus.Idle;
    store.dispatch(fetchLocations(currentCompany));
  }

  if (
    lastLocationLoadingStatus !== state.location.status &&
    state.location.status === LocationsLoadingStatus.Succeeded &&
    !currentLocation
  ) {
    // No current company selected, and all companies are loaded.
    let previous_location: Location | undefined,
      cookie_previous_location_id = cookies.get(
        cookie_previous_location_id_key
      );

    if (
      cookie_previous_location_id &&
      (previous_location = locationEntityAdapter
        .getSelectors()
        .selectById(state.location, cookie_previous_location_id))
    ) {
      setTimeout(function () {
        store.dispatch(
          setCurrentLocation(new SetCurrentLocationPayload(previous_location))
        );
      }, 100);
    } else {
      eventBus.dispatch("RequestLocationChange", { allowClose: false });
    }
  }
  lastLocationLoadingStatus = state.location.status;

  if (
    (lastCurrentLocationId !== "" && !currentLocation) ||
    (currentLocation && lastCurrentLocationId !== currentLocation.id)
  ) {
    lastCurrentLocationId = currentLocation ? currentLocation.id : "";

    dispatchUserActivityLoginEvent(
      currentCompany?.id,
      currentLocation?.id,
      currentUser
    );
  }

  if (currentCompany && currentLocation && currentUser) {
    if (
      currentUser.firstLogin &&
      !moment(currentUser.firstLogin).isBefore(moment(), "day") &&
      !cookies.get(cookie_shown_welcome_key)
    ) {
      // Today is the first login, and we have not shown the welcome popup yet.
      AlertDialog.show(
        <div>
          <p>Hoi{currentUser.givenName ? " " + currentUser.givenName : ""},</p>
          <p>
            Bij het aanmelden voor de RIE van Stigas heb je toegang gekregen tot
            ons nieuwe alles-in-één platform. Vanaf heden kan je Mijn Stigas
            gebruiken om alles in kaart te brengen om veilig en vitaal te werken
            binnen jouw organisatie.
          </p>
          <p>
            Met het overzicht van Mijn Stigas heb je compleet inzicht in welke
            taken en werkzaamheden er gedaan worden of moeten worden binnen jouw
            organisatie. Zo heeft Mijn Stigas een volledig overzicht van o.a.
            jouw ingevulde RIE, wie jouw bedrijfshulpverleners zijn, wanneer een
            BHV-certificaat van een collega verloopt en of het basiscontract
            arbodienstverlening van jouw bedrijf volledig voldoet aan de
            Arbowet.
          </p>
          <p>&nbsp;</p>
          <p>Ben je er klaar voor? Klik dan op onderstaande button.</p>
          <p>
            Wil je direct door naar de RIE?{" "}
            <a href={currentCompany.mijnStigasApiEditLink}>Klik dan hier</a>.
          </p>
        </div>,
        undefined,
        "Welkom terug in Mijn Stigas!",
        "Aan de slag"
      );

      let expires = new Date();
      expires.setDate(expires.getDate() + 2);
      cookies.set(cookie_shown_welcome_key, true, {
        path: "/",
        expires: expires,
      });
    }
  }
}

eventBus.addEventListener("CompanyChanged", function (company?: Company) {
  if (company) {
    let expires = new Date();
    expires.setMonth(expires.getMonth() + 1);

    cookies.set(cookie_previous_company_id_key, company.id, {
      path: "/",
      expires: expires,
    });
  }
});

eventBus.addEventListener("LocationChanged", function (location?: Location) {
  if (location) {
    let expires = new Date();
    expires.setMonth(expires.getMonth() + 1);

    cookies.set(cookie_previous_location_id_key, location.id, {
      path: "/",
      expires: expires,
    });
  }
});

store.subscribe(preloadOnUserChange);
