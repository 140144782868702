import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Location,
  locationEntityAdapter,
} from "../../Redux/reducer/location/slice";
import eventBus from "../../App/eventBus";

interface LocationSelectorProps {
  locations: Array<Location>;
  currentLocation?: Location;
}

function mapState(state: any) {
  return {
    locations: locationEntityAdapter.getSelectors().selectAll(state.location),
    currentLocation: state.location.currentLocation,
  };
}

class SidebarLocationSelector extends Component<LocationSelectorProps, {}> {
  constructor(props: any) {
    super(props);

    this.changeLocationOnClick = this.changeLocationOnClick.bind(this);
    this.locationExpand = this.locationExpand.bind(this);
    this.locationShrink = this.locationShrink.bind(this);
  }

  changeLocationOnClick(e: React.MouseEvent) {
    e.preventDefault();

    eventBus.dispatch("RequestLocationChange");
  }
  locationExpand(e: React.MouseEvent) {
    e.preventDefault();
    function locationExpandFunc() {
      let locationBtn = e.currentTarget as HTMLElement,
        locationDdInner = e.currentTarget.querySelector(
          ".location-inner"
        ) as HTMLElement,
        locationDd = e.currentTarget.querySelector(
          ".parent-container"
        ) as HTMLElement;

      if (locationDd.clientHeight === 0) {
        locationBtn.classList.add("expand");
        locationDd.style.height = locationDdInner.clientHeight + 30 + "px";
      } else {
        locationBtn.classList.remove("expand");
        locationDd.style.height = "0";
      }
    }
    function checkWidth() {
      var mq = window.matchMedia("(max-width: 768px)");
      if (mq.matches) {
        locationExpandFunc();
      }
    }
    checkWidth();
  }
  locationShrink(e: React.MouseEvent) {
    e.preventDefault();

    function locationShrinkFunc() {
      let locationBtn = e.currentTarget as HTMLElement,
        locationDd = e.currentTarget.querySelector(
          ".parent-container"
        ) as HTMLElement;

      locationBtn.classList.remove("expand");
      locationDd.style.height = "0";
    }
    function checkWidth() {
      var mq = window.matchMedia("(max-width: 768px)");
      if (mq.matches) {
        locationShrinkFunc();
      }
    }
    checkWidth();
  }

  render() {
    const location = this.props.currentLocation;

    return (
      <div>
        <div
          className="location-block"
          onMouseEnter={this.locationExpand}
          onClick={this.locationExpand}
          onMouseLeave={this.locationShrink}
        >
          <span className="Din-regular location-title">Vestiging</span>
          <div className="parent-container">
            <div className="location-inner">
              <div className="location-inner-title mobile">
                Geselecteerde vestiging
              </div>
              <a
                href="#vestiging-wijzigen"
                className="location"
                onClick={this.changeLocationOnClick}
              >
                {location ? (
                  <span>
                    {location.name}
                    <div className="location-line"></div>
                    <span className="location-address">{location.address}</span>
                    <span className="location-city">{location.city}</span>
                  </span>
                ) : (
                  <span>Geen locatie geselecteerd</span>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.767"
                  height="12.111"
                  viewBox="0 0 20.767 12.111"
                >
                  <g
                    id="angle-arrow-down"
                    transform="translate(-447.304 -8.144)"
                  >
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M20.56,60.63l-1.04-1.04a.654.654,0,0,0-.957,0l-8.178,8.178L2.206,59.59a.654.654,0,0,0-.957,0l-1.04,1.04a.654.654,0,0,0,0,.957l9.7,9.7a.654.654,0,0,0,.957,0l9.7-9.7a.656.656,0,0,0,0-.957Z"
                      transform="translate(447.304 -51.238)"
                      fill="#636466"
                    />
                  </g>
                </svg>
              </a>
              <a
                href="#vestiging-wijzigen"
                className="location mobile link"
                onClick={this.changeLocationOnClick}
              >
                Vestiging wijzigen
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapState, {})(SidebarLocationSelector);
