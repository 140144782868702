import {
  createAsyncThunk,
  createSlice,
  EntityState,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import Client from "../../../Client/Client";
import { JsonApiEntity } from "../JsonApiEntity";
import { Location } from "../location/slice";

export interface WorkbookProgress {
  name: string;
  title: string;
  all: number;
  processed: number;
  percentage: number;
  complete: boolean;
  mijnStigasApiEditLink: string;
}

export interface WorkbookAction {
  id: number;
  text: string;
  riskClassification: string;
  startDate: string | null;
  endDate: string | null;
  solved: boolean;
  solvedDate: string | null;
  mijnStigasApiEditLink: string;
}

export interface Workbook extends JsonApiEntity {
  name?: string;
  location_id: string;
  progress: Array<WorkbookProgress>;
  action: Array<WorkbookAction>;
  lastActivity?: string;
}

export function newWorkbook(location_id: string): Workbook {
  return {
    id: "",
    type: "workbook--workbook",
    created: "",
    changed: "",
    name: undefined,
    location_id: location_id,
    progress: [],
    action: [],
    lastActivity: undefined,
  };
}

export enum FetchWorkbookStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export const workbookEntityAdapter = createEntityAdapter<Workbook>();

export interface WorkbookState extends EntityState<Workbook> {
  fetchWorkbookStatus: FetchWorkbookStatus;
  error: string | null;
}

export const initialState: WorkbookState = workbookEntityAdapter.getInitialState(
  {
    fetchWorkbookStatus: FetchWorkbookStatus.Idle,
    error: null,
  }
);

export const fetchWorkbook = createAsyncThunk<
  Array<object>,
  Location,
  {
    extra: {
      client: Client;
    };
  }
>("workbook/fetchWorkbook", async function (arg: Location, thunkAPI) {
  const results = await thunkAPI.extra.client.findAllForLocation(
    arg,
    "workbook--workbook"
  );
  return results.data;
});

const slice = createSlice({
  name: "workbook",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchWorkbook.pending.toString()]: function (
      state: WorkbookState,
      action: any
    ) {
      state.fetchWorkbookStatus = FetchWorkbookStatus.Loading;
      workbookEntityAdapter.removeAll(state);
    },
    [fetchWorkbook.rejected.toString()]: function (
      state: WorkbookState,
      action: any
    ) {
      state.fetchWorkbookStatus = FetchWorkbookStatus.Failed;
      state.error = action.error.message;
    },
    [fetchWorkbook.fulfilled.toString()]: function (
      state: WorkbookState,
      action: any
    ) {
      state.fetchWorkbookStatus = FetchWorkbookStatus.Succeeded;
      workbookEntityAdapter.upsertMany(state, action.payload);
    },
  },
});

export function selectWorkbookByLocation(
  state: any,
  location: Location
): Workbook | undefined {
  return workbookEntityAdapter
    .getSelectors()
    .selectAll(state.workbook)
    .find(function (workbook) {
      return workbook.location_id === location.id;
    });
}

export default slice.reducer;
