import React, { Component } from "react";
import Progressbar from "../../Component/Progressbar/Progressbar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { User } from "../../Redux/reducer/user/slice";
import {
  Progress,
  selectLocationProgress,
} from "../../Redux/reducer/progress/slice";
import LocationSelector from "./SidebarLocationSelector";
import MoreInfoButton from "../../Component/Popup/MoreInfoButton";
import { companyHasTask } from "../../Redux/reducer/company/slice";
import eventBus from "../../App/eventBus";

type HeaderProps = {
  user?: User;
  progress?: Progress;
  hasAllowCompanyResetPermission?: boolean;
  numCompaniesCount: number;
  hasAdministratieProfileTask: boolean;
};

function mapState(state: any) {
  let location = state.location.currentLocation,
    company = state.company.currentCompany,
    user = state.user.user;

  return {
    user: user,
    progress: location
      ? selectLocationProgress(state, company, location, "total")
      : undefined,
    hasAllowCompanyResetPermission: user
      ? user.permissions.indexOf("allow company reset") >= 0
      : false,
    numCompaniesCount: state.company.ids.length,
    hasAdministratieProfileTask: company
      ? companyHasTask(company, "administratie_profile")
      : false,
  };
}

class Header extends Component<HeaderProps, {}> {
  constructor(props: any) {
    super(props);
    this.accountExpand = this.accountExpand.bind(this);
    this.accountShrink = this.accountShrink.bind(this);
  }

  accountExpand(e: React.MouseEvent) {
    e.preventDefault();

    let accountBtn = e.currentTarget as HTMLElement,
      accountDdInner = e.currentTarget.querySelector(
        ".account-dropdown-inner"
      ) as HTMLElement,
      accountDd = e.currentTarget.querySelector(
        ".account-dropdown"
      ) as HTMLElement;

    if (accountDd.clientHeight === 0) {
      accountBtn.classList.add("expand");
      accountDd.style.height = accountDdInner.clientHeight + 30 + "px";
    } else {
      accountBtn.classList.remove("expand");
      accountDd.style.height = "0";
    }
  }
  accountShrink(e: React.MouseEvent) {
    e.preventDefault();

    let accountBtn = e.currentTarget as HTMLElement,
      accountDd = e.currentTarget.querySelector(
        ".account-dropdown"
      ) as HTMLElement;
    accountBtn.classList.remove("expand");
    accountDd.style.height = "0";
  }

  changeCompanyOnClick(e: React.MouseEvent) {
    e.preventDefault();

    eventBus.dispatch("RequestCompanyChange");
  }

  render() {
    let header_progress = null,
      header_account = null;

    if (this.props.user) {
      header_progress = (
        <div className="header-progress">
          <span className="progress-title">Totale voortgang</span>
          <Progressbar
            progress={this.props.progress ? this.props.progress.percentage : 0}
          />

          <MoreInfoButton title="Voortgang Mijn Stigas-taken">
            <p>
              Hier kun je je voorlopige voortgang zien van je huidige taken in
              Mijn Stigas. Probeer alle taken af te ronden om jouw organisatie
              veilig en gezond te houden.
              <br />
              Indien er bij een taak 'Ga verder' staat, kan je deze taak
              eenvoudig oppakken door hier op te klikken.
            </p>
          </MoreInfoButton>
        </div>
      );

      header_account = (
        <div className="header-account">
          <LocationSelector />
          <div
            className="account-button"
            onMouseEnter={this.accountExpand}
            onMouseLeave={this.accountShrink}
            onClick={this.accountExpand}
          >
            <div className="account-image">
              <span>{this.props.user.name.charAt(0)}</span>
            </div>
            <div className="account-dropdown">
              <ul className="account-dropdown-inner">
                <li className="current-user">
                  <span>{this.props.user.name}</span>
                </li>
                {this.props.hasAllowCompanyResetPermission ? (
                  <li>
                    <Link to="/company/reset">
                      <span>Bedrijfsgegevens wissen</span>
                    </Link>
                  </li>
                ) : null}
                {this.props.numCompaniesCount > 1 ? (
                  <li>
                    <a
                      href="#bedrijf-wijzigen"
                      onClick={this.changeCompanyOnClick}
                    >
                      Wisselen van bedrijf
                    </a>
                  </li>
                ) : null}
                {this.props.hasAdministratieProfileTask ? (
                  <li>
                    <Link to="/administratie/profiel">
                      <span>Profiel</span>
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link to="/uitloggen">
                    <span>Uitloggen</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }

    return (
      <header>
        <div className="header-container">
          <div className="header-brand">
            <Link to="/">
              <img
                src="/img/logo.png"
                className="header-logo"
                alt="Mijn Stigas"
              />
              <div className="header-name">
                <span className="Din-light">Dashboard</span>
                <span className="Din-regular">Mijn Stigas</span>
              </div>
            </Link>
          </div>
          {header_progress}
          <div className="header-links">
            <a
              href="https://www.stigas.nl"
              className="header-link stigas"
              title="Stigas"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.029"
                height="17.876"
                viewBox="0 0 18.029 17.876"
              >
                <g transform="translate(0.426 0.764)">
                  <path
                    className="a"
                    d="M15.562-.764H1.615A2.265,2.265,0,0,0-.426,1.668V14.681a2.265,2.265,0,0,0,2.041,2.431H15.562A2.265,2.265,0,0,0,17.6,14.681V1.668A2.265,2.265,0,0,0,15.562-.764Zm0,16.731H1.615c-.485,0-.9-.589-.9-1.286V5.029H16.457v9.652C16.457,15.378,16.047,15.967,15.562,15.967ZM1.615.381H15.562c.485,0,.9.589.9,1.286V3.884H.719V1.668C.719.971,1.13.381,1.615.381Z"
                  />
                  <circle
                    className="a"
                    cx="0.91"
                    cy="0.91"
                    r="0.91"
                    transform="translate(2.49 1.222)"
                  />
                  <circle
                    className="a"
                    cx="0.91"
                    cy="0.91"
                    r="0.91"
                    transform="translate(5.278 1.222)"
                  />
                  <circle
                    className="a"
                    cx="0.91"
                    cy="0.91"
                    r="0.91"
                    transform="translate(8.067 1.222)"
                  />
                </g>
              </svg>
              <span>Stigas.nl</span>
            </a>
            <a
              href="https://www.stigas.nl/agroarbo/"
              className="header-link arbo"
              title="Arbocatalogus"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.439"
                height="17.439"
                viewBox="0 0 17.439 17.439"
              >
                <path
                  className="a"
                  d="M17.2.948H2.662A1.451,1.451,0,0,0,1.211,2.4V16.937a1.451,1.451,0,0,0,1.451,1.451H17.2a1.451,1.451,0,0,0,1.451-1.451V2.4A1.451,1.451,0,0,0,17.2.948ZM9.93,2.34A1.039,1.039,0,1,1,8.891,3.379,1.039,1.039,0,0,1,9.93,2.34ZM4.221,12.433a.661.661,0,0,1-.2-.325L3.054,8.5a.393.393,0,0,1-.016-.115.445.445,0,0,1,.354-.434.47.47,0,0,1,.529.356l.687,2.118-.013.02a.889.889,0,0,0-.089.673.876.876,0,0,0,.416.54l1.273.736a.222.222,0,0,0,.222-.385l-1.273-.735A.445.445,0,0,1,4.934,11a.457.457,0,0,1,.045-.338.444.444,0,0,1,.607-.163h0L7.55,11.652a1.567,1.567,0,0,1,.769,1.342v2.094H6.1V14.6a1.123,1.123,0,0,0-.371-.831Zm4.32,4.434H5.874V15.533H8.541Zm2.118-4.6a.512.512,0,0,1-.534-.487v-2.9H9.736v2.9a.536.536,0,0,1-1.067,0c0-3.189,0-4.936,0-5.053L7.954,8.164a.463.463,0,0,1-.589.2.392.392,0,0,1-.22-.538L8.628,4.862a.486.486,0,0,1,.432-.236c.063,0,1.674,0,1.742,0a.486.486,0,0,1,.432.236l1.482,2.965a.392.392,0,0,1-.22.538.463.463,0,0,1-.589-.2l-.716-1.433c0,.269,0,3.674,0,5.053A.512.512,0,0,1,10.659,12.271Zm3.354,4.6H11.346V15.533h2.667ZM16.833,8.5l-.967,3.609a.662.662,0,0,1-.2.325l-1.5,1.336a1.123,1.123,0,0,0-.371.831v.489H11.568V12.995a1.568,1.568,0,0,1,.769-1.342L14.3,10.5h0a.444.444,0,0,1,.607.163.458.458,0,0,1,.044.338.444.444,0,0,1-.209.269l-1.273.735a.222.222,0,0,0,.222.385l1.273-.736a.877.877,0,0,0,.416-.54.891.891,0,0,0-.089-.673l-.013-.02.687-2.118A.47.47,0,0,1,16.5,7.95a.445.445,0,0,1,.353.434A.376.376,0,0,1,16.833,8.5Z"
                  transform="translate(-1.211 -0.948)"
                />
              </svg>
              <span>Arbocatalogus</span>
            </a>
            <a
              href="https://www.stigas.nl/mijn-stigas-veelgestelde-vragen/"
              target="_blank"
              rel="noreferrer"
              className="header-link vragen"
              title="Vragen"
            >
              <svg
                viewBox="0 0 512 512"
                width="512pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m277.332031 384c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0" />
                <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0" />
                <path d="m256 314.667969c-8.832031 0-16-7.167969-16-16v-21.546875c0-20.308594 12.886719-38.507813 32.042969-45.269532 25.492187-8.980468 42.625-36.140624 42.625-55.851562 0-32.363281-26.304688-58.667969-58.667969-58.667969s-58.667969 26.304688-58.667969 58.667969c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16c0-49.984375 40.664063-90.667969 90.667969-90.667969s90.667969 40.683594 90.667969 90.667969c0 35.585938-28.097657 73.367188-63.980469 86.039062-6.398438 2.238282-10.6875 8.316407-10.6875 15.101563v21.527344c0 8.832031-7.167969 16-16 16zm0 0" />
              </svg>
              <span>Vragen?</span>
            </a>
          </div>
          {header_account}
        </div>
      </header>
    );
  }
}

export default connect(mapState, {})(Header);
