import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Location,
  locationEntityAdapter,
  setCurrentLocation,
  SetCurrentLocationPayload,
} from "../Redux/reducer/location/slice";
import store from "../Redux/store";
import Popup from "../Component/Popup/Popup";
import eventBus from "../App/eventBus";

interface LocationSelectorProps {
  locations: Array<Location>;
  currentLocation?: Location;
}

interface LocationSelectorState {
  popupAllowClose: boolean;
  popupIsOpened: boolean;
  popupSelectedLocation?: Location;
}

function mapState(state: any) {
  return {
    locations: locationEntityAdapter.getSelectors().selectAll(state.location),
    currentLocation: state.location.currentLocation,
  };
}

class LocationSelector extends Component<
  LocationSelectorProps,
  LocationSelectorState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      popupAllowClose: true,
      popupIsOpened: false,
      popupSelectedLocation: undefined,
    };

    this.selectLocationOnClick = this.selectLocationOnClick.bind(this);
    this.changeButtonOnClick = this.changeButtonOnClick.bind(this);
    this.cancelButtonOnClick = this.cancelButtonOnClick.bind(this);
  }

  componentDidMount() {
    eventBus.addEventListener(
      "RequestLocationChange",
      this.onRequestLocationChange.bind(this)
    );
  }

  onRequestLocationChange(data: any) {
    if (!data) {
      data = {
        allowClose: true,
      };
    }
    this.setState({
      popupAllowClose: data.allowClose === true,
      popupIsOpened: true,
      popupSelectedLocation: this.props.currentLocation,
    });
  }

  selectLocationOnClick(e: React.MouseEvent) {
    let target: HTMLElement | null = e.target as HTMLElement,
      location_id = target.getAttribute("data-location-id"),
      location = location_id
        ? locationEntityAdapter
            .getSelectors()
            .selectById(store.getState().location, location_id)
        : undefined,
      self = this;

    if (!location) {
      return;
    }

    this.setState(
      {
        popupSelectedLocation: location,
      },
      function () {
        if (!self.state.popupAllowClose) {
          self.changeButtonOnClick();
        }
      }
    );
  }

  changeButtonOnClick() {
    store.dispatch(
      setCurrentLocation(
        new SetCurrentLocationPayload(this.state.popupSelectedLocation)
      )
    );
    this.setState({
      popupIsOpened: false,
    });
  }

  cancelButtonOnClick() {
    this.setState({
      popupIsOpened: false,
    });
  }

  render() {
    let self = this,
      content;

    if (!this.props.locations || this.props.locations.length === 0) {
      content = (
        <div>
          <div className="wait-message">
            <div className="wait-animation"></div>
            Een ogenblik geduld aub...
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          {this.state.popupAllowClose ? (
            <p>Selecteer hieronder de vestiging waarmee je aan de slag wilt:</p>
          ) : (
            <p>Selecteer hieronder de vestiging om aan de slag te gaan:</p>
          )}
          <div className="selector-box">
            {this.props.locations?.map(function (location, index) {
              let buttonClass = "button";
              if (
                self.state.popupSelectedLocation &&
                self.state.popupSelectedLocation.id === location.id
              ) {
                buttonClass += " selected";
              }

              return (
                <div className="location selector-btn" key={index}>
                  <button
                    className={buttonClass}
                    onClick={self.selectLocationOnClick}
                    data-location-id={location.id}
                  >
                    {location.name}:&nbsp;
                    {location.address} {location.city}
                  </button>
                </div>
              );
            })}
          </div>
          {this.state.popupAllowClose ? (
            <div className="popup-buttons">
              <button
                className="button secondary"
                onClick={this.changeButtonOnClick}
              >
                Wijzig vestiging
              </button>
              <button className="button" onClick={this.cancelButtonOnClick}>
                Annuleren
              </button>
            </div>
          ) : (
            <div className="popup-buttons">
              <button
                className="button secondary"
                onClick={this.changeButtonOnClick}
              >
                Selecteer vestiging
              </button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="location-selector-container">
        <Popup
          title="Selecteer vestiging"
          isOpened={this.state.popupIsOpened}
          showCloseButton={this.state.popupAllowClose}
          closeCallback={this.cancelButtonOnClick}
        >
          {content}
        </Popup>
      </div>
    );
  }
}

export default connect(mapState, {})(LocationSelector);
