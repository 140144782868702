import { JsonApiModel } from "../JsonApiEntity";
import { newCompany } from "./slice";
import { getSubjectUrl } from "../subject/subject";
import { getTaskUrl } from "../task/task";

function weightSorter(a: any, b: any) {
  if (a.weight === b.weight) {
    return 0;
  }
  return a.weight > b.weight ? 1 : -1;
}

function deserializer(companyData: any, included: any, jsonApi: any) {
  let company = newCompany(),
    i,
    url;

  company.id = companyData.id;
  company.created = companyData.attributes.created;
  company.changed = companyData.attributes.changed;
  company.name = companyData.attributes.name;
  company.task = companyData.attributes.task.filter(function (task: any) {
    return task !== null;
  });
  company.subject = companyData.attributes.subject.filter(function (
    subject: any
  ) {
    return subject !== null;
  });

  for (i in company.subject) {
    if (!company.subject.hasOwnProperty(i)) {
      continue;
    }
    company.subject[i].url = getSubjectUrl(company.subject[i]);
  }

  for (i in company.task) {
    if (!company.task.hasOwnProperty(i)) {
      continue;
    }
    url = getTaskUrl(company.task[i], company);
    if (url) {
      company.task[i].url = url;
    }
  }

  // Sort subjects and tasks by weight.
  company.subject.sort(weightSorter);
  company.task.sort(weightSorter);

  company.employeesFixed = companyData.attributes.employees_fixed;
  company.employeesFlex = companyData.attributes.employees_flex;
  company.certified =
    companyData.attributes.certified === null
      ? undefined
      : companyData.attributes.certified === "1";
  company.certifications =
    companyData.attributes.visitor_address_street === null
      ? undefined
      : companyData.attributes.certifications;
  company.visitorAddressStreet =
    companyData.attributes.visitor_address_street === null
      ? undefined
      : companyData.attributes.visitor_address_street;
  company.visitorAddressZipcode =
    companyData.attributes.visitor_address_zipcode === null
      ? undefined
      : companyData.attributes.visitor_address_zipcode;
  company.visitorAddressCity =
    companyData.attributes.visitor_address_city === null
      ? undefined
      : companyData.attributes.visitor_address_city;
  company.contact =
    companyData.attributes.contact === null
      ? undefined
      : companyData.attributes.contact;
  company.email =
    companyData.attributes.email === null
      ? undefined
      : companyData.attributes.email;
  company.telephone =
    companyData.attributes.telephone === null
      ? undefined
      : companyData.attributes.telephone;
  company.fax =
    companyData.attributes.fax === null
      ? undefined
      : companyData.attributes.fax;
  company.description =
    companyData.attributes.description === null
      ? undefined
      : companyData.attributes.description;
  company.mijnStigasApiEditLink =
    companyData.attributes.mijn_stigas_api_edit_link === null
      ? undefined
      : companyData.attributes.mijn_stigas_api_edit_link.uri;

  return company;
}

const jsonApiModel: JsonApiModel = {
  name: "company--company",
  constructor: {
    name: "",
  },
  options: {
    collectionPath: "company/company",
    deserializer: deserializer,
  },
};

export default jsonApiModel;
