import JsonApi from "devour-client";
import { JsonApiEntity, JsonApiModel } from "../Redux/reducer/JsonApiEntity";
import { Location } from "../Redux/reducer/location/slice";
import { Company } from "../Redux/reducer/company/slice";
import { getQueryParameter } from "../App/getQueryParameter";
import eventBus from "../App/eventBus";
import AlertDialog from "../Component/Popup/AlertDialog";
import {
  pendingRequestInterceptor,
  pendingRequestResponseInterceptor,
} from "./axios/pendingRequests";

export default abstract class Client {
  private baseUrl: string;
  private modelIncludeParams: any;
  public jsonApi: any;
  private xdebugSessionId?: string;

  public constructor(baseUrl: string) {
    if (baseUrl.indexOf("/") === 0) {
      // Make sure the base url is absolute.
      baseUrl =
        window.location.protocol + "//" + window.location.hostname + baseUrl;
    }

    this.baseUrl = baseUrl;
    this.jsonApi = new JsonApi({
      apiUrl: this.baseUrl + "/jsonapi",
      pluralize: false,
    });
    this.modelIncludeParams = {};

    if (window.sessionStorage) {
      this.xdebugSessionId = getQueryParameter("XDEBUG_SESSION") || undefined;

      if (!this.xdebugSessionId) {
        this.xdebugSessionId =
          window.sessionStorage.getItem("XDEBUG_SESSION") || undefined;
      } else {
        window.sessionStorage.setItem("XDEBUG_SESSION", this.xdebugSessionId);
      }
    }

    this.jsonApi.axios.interceptors.request.use(
      pendingRequestInterceptor,
      function (error: any) {
        return Promise.reject(error);
      }
    );

    this.jsonApi.axios.interceptors.response.use(
      pendingRequestResponseInterceptor,
      function (error: any) {
        return Promise.reject(error);
      }
    );

    eventBus.addEventListener(
      "UserActivityLoginEvent",
      this.onUserActivityLoginEvent.bind(this)
    );

    eventBus.addEventListener(
      "UserCookiebarStateEvent",
      this.onUserCookiebarStateEvent.bind(this)
    );

    eventBus.addEventListener("RieExternalVisit", this.onVisitRie.bind(this));
    eventBus.addEventListener(
      "AccidentExternalVisit",
      this.onAccidentExternalVisit.bind(this)
    );
    eventBus.addEventListener(
      "ArboContractVisit",
      this.onArboContractVisit.bind(this)
    );
    eventBus.addEventListener(
      "ProfileExternalVisit",
      this.onProfileExternalVisit.bind(this)
    );
    eventBus.addEventListener(
      "BhvDownloadCertificate",
      this.onBhvDownloadCertificate.bind(this)
    );
    eventBus.addEventListener(
      "BasiscontractDocumentWeergave",
      this.onBasiscontractDocumentWeergave.bind(this)
    );
    eventBus.addEventListener(
      "VoorlichtingVeiligWerkenWeergaveDocument",
      this.onVoorlichtingVeiligWerkenWeergaveDocument.bind(this)
    );
  }

  getBaseUrl(): string {
    return this.baseUrl;
  }

  protected abstract getAuthorizationBearer(): string;

  defineJsonApiModel(jsonApiModel: JsonApiModel) {
    let options = jsonApiModel.options || {};

    if (options.serializer) {
      let callback = options.serializer;
      options.serializer = function (data: any, included: any, jsonApi: any) {
        return callback(data, included, this || jsonApi);
      };
    }

    if (options.deserializer) {
      let callback = options.deserializer;
      options.deserializer = function (data: any, included: any, jsonApi: any) {
        return callback(data, included, this || jsonApi);
      };
    }

    this.modelIncludeParams[jsonApiModel.name] = jsonApiModel.includeParams;

    return this.jsonApi.define(
      jsonApiModel.name,
      jsonApiModel.constructor,
      options
    );
  }

  findAll(type: string, params?: any): any {
    this.jsonApi.bearer = this.getAuthorizationBearer();

    if (!params) {
      params = {};
    }

    if (this.xdebugSessionId) {
      params["XDEBUG_SESSION_START"] = this.xdebugSessionId;
    }

    if (this.modelIncludeParams[type]) {
      params["include"] = this.modelIncludeParams[type].join(",");
    }

    return this.jsonApi.findAll(type, params);
  }

  findAllForCompany(company: Company, type: string): any {
    let params: any = {
      filter: {
        "company.id": company.id,
      },
      fields: {
        "file--file": "uri,url,filename,filemime,filesize",
      },
    };

    if (this.xdebugSessionId) {
      params["XDEBUG_SESSION_START"] = this.xdebugSessionId;
    }

    if (this.modelIncludeParams[type]) {
      params["include"] = this.modelIncludeParams[type].join(",");
    }

    return this.findAll(type, params);
  }

  findAllForLocation(location: Location, type: string): any {
    let params: any = {
      filter: {
        "location.id": location.id,
      },
      fields: {
        "file--file": "uri,url,filename,filemime,filesize",
      },
    };

    if (this.xdebugSessionId) {
      params["XDEBUG_SESSION_START"] = this.xdebugSessionId;
    }

    if (this.modelIncludeParams[type]) {
      params["include"] = this.modelIncludeParams[type].join(",");
    }

    return this.findAll(type, params);
  }

  create(object: JsonApiEntity, params: any = {}): any {
    let objectToCreate: any = { ...Object.assign({}, object), ...params };

    delete objectToCreate.id;

    this.jsonApi.bearer = this.getAuthorizationBearer();

    return this.jsonApi.create(object.type, objectToCreate);
  }

  delete(objectToDestroy: JsonApiEntity): any {
    this.jsonApi.bearer = this.getAuthorizationBearer();
    return this.jsonApi.destroy(objectToDestroy.type, objectToDestroy.id);
  }

  update(objectToUpdate: JsonApiEntity): any {
    this.jsonApi.bearer = this.getAuthorizationBearer();
    return this.jsonApi.update(objectToUpdate.type, objectToUpdate);
  }

  async getUrl(url: string) {
    this.jsonApi.bearer = this.getAuthorizationBearer();

    if (url.indexOf("/") === 0) {
      // Make relative url absolute.
      url = window.location.protocol + "//" + window.location.hostname + url;
    }

    return await this.jsonApi.axios.get(url, {
      headers: {
        Authorization: "Bearer " + this.jsonApi.bearer,
      },
    });
  }

  async createFile(type: string, field: string, file: any) {
    if (!this.jsonApi.models[type]) {
      throw Error("Model " + type + " not defined.");
    }

    if (!this.jsonApi.models[type].attributes[field]) {
      throw Error("Field " + field + " does not exist on model " + type + ".");
    }

    let url =
        "/jsonapi/" +
        this.jsonApi.models[type].options.collectionPath +
        "/" +
        field,
      formData = new FormData();

    formData.append("file", file);

    this.jsonApi.bearer = this.getAuthorizationBearer();
    return await this.jsonApi.axios.post(this.baseUrl + url, formData, {
      headers: {
        Authorization: "Bearer " + this.jsonApi.bearer,
      },
    });
  }

  async updateFile(object: JsonApiEntity, field: string, file: any) {
    if (!this.jsonApi.models[object.type].attributes[field]) {
      throw Error(
        "Field " + field + " does not exist on model " + object.type + "."
      );
    }

    let url =
        "/jsonapi/" +
        this.jsonApi.models[object.type].options.collectionPath +
        "/" +
        object.id +
        "/" +
        field,
      formData = new FormData();

    formData.append("file", file);

    this.jsonApi.bearer = this.getAuthorizationBearer();
    return await this.jsonApi.axios.post(this.baseUrl + url, formData, {
      headers: {
        Authorization: "Bearer " + this.jsonApi.bearer,
      },
    });
  }

  async deleteFile(object: JsonApiEntity) {
    let url =
      "/jsonapi/" +
      this.jsonApi.models[object.type].options.collectionPath +
      "/" +
      object.id;

    this.jsonApi.bearer = this.getAuthorizationBearer();
    return await this.jsonApi.axios.delete(this.baseUrl + url, {
      headers: {
        Authorization: "Bearer " + this.jsonApi.bearer,
      },
    });
  }

  async updateProgress() {
    let url = "/jsonapi";

    this.jsonApi.bearer = this.getAuthorizationBearer();
    return await this.jsonApi.axios.get(this.baseUrl + url, {
      headers: {
        Authorization: "Bearer " + this.jsonApi.bearer,
      },
    });
  }

  async companyReset(company_id: string) {
    this.jsonApi.bearer = this.getAuthorizationBearer();
    return await this.jsonApi.axios.get(
      this.baseUrl + "/company/reset/" + encodeURIComponent(company_id),
      {
        headers: {
          Authorization: "Bearer " + this.jsonApi.bearer,
        },
      }
    );
  }

  async post(url: string, data: any) {
    this.jsonApi.bearer = this.getAuthorizationBearer();
    return await this.jsonApi.axios.post(this.baseUrl + url, data, {
      headers: {
        Authorization: "Bearer " + this.jsonApi.bearer,
      },
    });
  }

  onUserActivityLoginEvent(data: any) {
    this.post("/user-activity/login", {
      company: data.company_id,
      location: data.location_id,
    });
  }

  onUserCookiebarStateEvent(data: any) {
    this.post("/cookiebar/state", {
      state: data.state,
    });
  }

  onVisitRie(data: any) {
    this.post("/rie/visit/external", {
      location: data.location_id,
      page: data.page,
      id: data.id ? data.id : null,
    });
  }

  onAccidentExternalVisit(data: any) {
    this.post("/accident/visit/external", {
      location: data.location_id,
    });
  }

  onArboContractVisit(data: any) {
    this.post("/arbocontract/visit", {
      company: data.company_id,
    });
  }

  onProfileExternalVisit(data: any) {
    this.post("/profile/visit/external", {
      company: data.company_id ? data.company_id : null,
      location: data.location_id ? data.location_id : null,
      page: data.page,
    });
  }

  onBhvDownloadCertificate(data: any) {
    this.post("/bhvperson/download", {
      bhvperson: data.bhvperson.id,
    });
  }

  onBasiscontractDocumentWeergave(data: any) {
    this.post("/arbocontract/download", {
      arbocontract: data.arbocontract.id,
    });
  }

  onVoorlichtingVeiligWerkenWeergaveDocument(data: any) {
    this.post("/safeworkinstruction/download", {
      safeworkinstruction: data.safeworkinstruction.id,
    });
  }

  async generateTokenForData(data: any, onTokenGeneratedCallback: Function) {
    this.jsonApi.bearer = this.getAuthorizationBearer();
    this.jsonApi.axios
      .post(this.baseUrl + "/mijnstigas-tokendata", data, {
        headers: {
          Authorization: "Bearer " + this.jsonApi.bearer,
        },
      })
      .then(function (response: any) {
        if (response.status !== 201 || !response.data.token) {
          AlertDialog.showWithDetails(
            "Er is een probleem opgetreden. Probeer het nogmaals",
            response.status
          );
          return;
        }
        onTokenGeneratedCallback(response.data.token);
      })
      .catch(function (error: any) {
        AlertDialog.showWithDetails(
          "Er is een probleem opgetreden. Probeer het nogmaals",
          error.message
        );
      });
  }
}
