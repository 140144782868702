import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import combinedReducers from "./reducer/combined";
import { composeWithDevTools } from "redux-devtools-extension";
import { devour_client_progress_middleware } from "../Client/middleware/progress_middleware";
import fileJsonApiModel from "./reducer/file/jsonApiModel";
import companyJsonApiModel from "./reducer/company/jsonApiModel";
import locationJsonApiModel from "./reducer/location/jsonApiModel";
import bhvpersonJsonApiModel from "./reducer/bhvperson/jsonApiModel";
import premedJsonApiModel from "./reducer/premed/jsonApiModel";
import safeworkinstructionJsonApiModel from "./reducer/safeworkinstruction/jsonApiModel";
import arbocontractJsonApiModel from "./reducer/arbocontract/jsonApiModel";
import workbookJsonApiModel from "./reducer/workbook/jsonApiModel";
import advisorJsonApiModel from "./reducer/advisor/jsonApiModel";
import sectorJsonApiModel from "./reducer/sector/jsonApiModel";
import { getDefaultClient } from "../Client/default_client";
import { devour_client_user_activity_middleware } from "../Client/middleware/user_activity_middleware";
import { devour_client_user_permissions_middleware } from "../Client/middleware/user_permissions_middleware";
import { devour_client_req_withcredentials_middleware_true } from "../Client/middleware/req_withcredentials_true";
import { devour_client_cookiebar_middleware } from "../Client/middleware/cookiebar_middleware";

const default_client = getDefaultClient();

const composedEnhancer = composeWithDevTools(
  applyMiddleware(thunk.withExtraArgument({ client: default_client }))
);

default_client.jsonApi.insertMiddlewareBefore(
  "axios-request",
  devour_client_req_withcredentials_middleware_true
);

default_client.jsonApi.insertMiddlewareBefore(
  "response",
  devour_client_progress_middleware
);

default_client.jsonApi.insertMiddlewareBefore(
  "response",
  devour_client_user_activity_middleware
);

default_client.jsonApi.insertMiddlewareBefore(
  "response",
  devour_client_user_permissions_middleware
);

default_client.jsonApi.insertMiddlewareBefore(
  "response",
  devour_client_cookiebar_middleware
);

default_client.defineJsonApiModel(fileJsonApiModel);
default_client.defineJsonApiModel(companyJsonApiModel);
default_client.defineJsonApiModel(locationJsonApiModel);
default_client.defineJsonApiModel(bhvpersonJsonApiModel);
default_client.defineJsonApiModel(premedJsonApiModel);
default_client.defineJsonApiModel(safeworkinstructionJsonApiModel);
default_client.defineJsonApiModel(arbocontractJsonApiModel);
default_client.defineJsonApiModel(arbocontractJsonApiModel);
default_client.defineJsonApiModel(workbookJsonApiModel);
default_client.defineJsonApiModel(advisorJsonApiModel);
default_client.defineJsonApiModel(sectorJsonApiModel);

const store = createStore(combinedReducers, composedEnhancer);

export default store;
