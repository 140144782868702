import React, { Component } from "react";
import "./Popup.scss";
import ReactDOM from "react-dom";

type AlertDialogProps = {
  title?: string;
  isOpened: boolean;
  closeButtonTitle?: string;
  closeCallback?: Function;
  isUnclosable?: boolean;
};

export default class AlertDialog extends Component<AlertDialogProps, {}> {
  private handleCloseButtonOnClick(e: React.MouseEvent) {
    e.preventDefault();
    this.close();
  }

  close() {
    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  }

  render() {
    if (this.props.isOpened) {
      return (
        <div className="popup-container AlertDialog">
          <div
            className="popup-backdrop"
            onClick={this.handleCloseButtonOnClick.bind(this)}
          ></div>
          <div className="popup">
            <h2 className="popup-title">
              {this.props.title ? this.props.title : "Mededeling"}
            </h2>
            {this.props.children}
            {!this.props.isUnclosable ? (
              <div className="popup-buttons">
                <button
                  className="button secondary"
                  onClick={this.handleCloseButtonOnClick.bind(this)}
                >
                  {this.props.closeButtonTitle
                    ? this.props.closeButtonTitle
                    : "Sluiten"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  static show(
    message: any,
    closeCallback?: Function,
    title?: string,
    closeButtonTitle?: string,
    isUnclosable?: boolean
  ) {
    let container = document.createElement("div");
    document.body.appendChild(container);

    function destroy() {
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    }

    function handleOnClose() {
      if (isUnclosable) {
        return;
      }

      destroy();

      if (closeCallback) {
        closeCallback();
      }
    }

    ReactDOM.render(
      <AlertDialog
        title={title}
        isOpened={true}
        closeButtonTitle={closeButtonTitle}
        closeCallback={handleOnClose}
        isUnclosable={isUnclosable}
      >
        {typeof message === "string" ? <p>{message}</p> : message}
      </AlertDialog>,
      container
    );
  }

  static showWithDetails(
    message: string,
    details: string,
    closeCallback?: Function,
    title?: string,
    closeButtonTitle?: string,
    isUnclosable?: boolean
  ) {
    let container = document.createElement("div");
    document.body.appendChild(container);

    function destroy() {
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    }

    function handleOnClose() {
      if (isUnclosable) {
        return;
      }

      destroy();

      if (closeCallback) {
        closeCallback();
      }
    }

    ReactDOM.render(
      <AlertDialog
        title={title}
        isOpened={true}
        closeButtonTitle={closeButtonTitle}
        closeCallback={handleOnClose}
        isUnclosable={isUnclosable}
      >
        <p>{message}</p>
        <pre>{details}</pre>
      </AlertDialog>,
      container
    );
  }
}
