import React, { Component } from "react";
import DefaultPageLayout from "../DefaultPageLayout/DefaultPageLayout";

type TextPageLayoutProps = {
  title: string;
};

export default class TextPageLayout extends Component<TextPageLayoutProps, {}> {
  render() {
    return (
      <DefaultPageLayout title={this.props.title}>
        <div className="task-page-layout">
          <div className="container">
            <div className="row task-header">
              <div className="col-12">
                <h1>{this.props.title}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">{this.props.children}</div>
            </div>
          </div>
        </div>
      </DefaultPageLayout>
    );
  }
}
