import store from "../../Redux/store";
import { updateAllLocationsProgress } from "../../Redux/reducer/progress/slice";
import { getUpdateProgressPayloadFromMetaObject } from "./getUpdateProgressPayloadFromMetaObject";

export const devour_client_progress_middleware = {
  name: "progress",
  res: function (payload: any) {
    if (!payload.res.data.meta || !payload.res.data.meta.progress) {
      return payload;
    }

    let updateProgressPayload = getUpdateProgressPayloadFromMetaObject(
      payload.res.data.meta
    );

    store.dispatch(updateAllLocationsProgress(updateProgressPayload));

    return payload;
  },
};
