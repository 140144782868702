import React, { Component } from "react";
import "./Popup.scss";
import Popup from "./Popup";

interface MoreInfoButtonProps {
  title: string;
  onClickCallback?: Function;
}

interface MoreInfoButtonState {
  isOpened: boolean;
}

export default class MoreInfoButton extends Component<
  MoreInfoButtonProps,
  MoreInfoButtonState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpened: false,
    };

    this.handleMoreInfoButtonOnClick = this.handleMoreInfoButtonOnClick.bind(
      this
    );
    this.popupCloseCallback = this.popupCloseCallback.bind(this);
  }

  handleMoreInfoButtonOnClick(e: React.MouseEvent) {
    e.preventDefault();
    if (this.props.onClickCallback) {
      this.props.onClickCallback();
      return;
    }

    this.setState({
      isOpened: true,
    });
  }

  popupCloseCallback() {
    this.setState({
      isOpened: false,
    });
  }

  render() {
    return (
      <div className="more-info-button-container MoreInfoButton">
        <button
          className="more-info-button"
          type="button"
          onClick={this.handleMoreInfoButtonOnClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
          >
            <g className="info-icon" transform="translate(-1352 -388)">
              <circle
                className="a"
                cx="9.5"
                cy="9.5"
                r="9.5"
                transform="translate(1352 388)"
              />
              <g transform="translate(1356 392)">
                <path
                  className="b"
                  d="M5.575,0A5.575,5.575,0,1,0,11.15,5.575,5.581,5.581,0,0,0,5.575,0Zm0,10.136a4.561,4.561,0,1,1,4.561-4.561A4.566,4.566,0,0,1,5.575,10.136Z"
                />
                <path
                  className="b"
                  d="M145.678,70a.676.676,0,1,0,.676.676A.677.677,0,0,0,145.678,70Z"
                  transform="translate(-140.103 -67.635)"
                />
                <path
                  className="b"
                  d="M150.507,140a.507.507,0,0,0-.507.507v3.041a.507.507,0,0,0,1.014,0v-3.041A.507.507,0,0,0,150.507,140Z"
                  transform="translate(-144.932 -135.27)"
                />
              </g>
            </g>
          </svg>
        </button>
        <Popup
          title={this.props.title}
          subTitle="Meer info"
          showCloseButton={true}
          isOpened={this.state.isOpened}
          closeCallback={this.popupCloseCallback}
        >
          {this.props.children}
        </Popup>
      </div>
    );
  }
}
