import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Company,
  companyEntityAdapter,
  setCurrentCompany,
  SetCurrentCompanyPayload,
} from "../Redux/reducer/company/slice";
import store from "../Redux/store";
import Popup from "../Component/Popup/Popup";
import eventBus from "../App/eventBus";

interface CompanySelectorProps {
  companys: Array<Company>;
  currentCompany?: Company;
}

interface CompanySelectorState {
  popupAllowClose: boolean;
  popupIsOpened: boolean;
  popupSelectedCompany?: Company;
}

function mapState(state: any) {
  return {
    companys: companyEntityAdapter.getSelectors().selectAll(state.company),
    currentCompany: state.company.currentCompany,
  };
}

class CompanySelector extends Component<
  CompanySelectorProps,
  CompanySelectorState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      popupAllowClose: true,
      popupIsOpened: false,
      popupSelectedCompany: undefined,
    };

    this.selectCompanyOnClick = this.selectCompanyOnClick.bind(this);
    this.changeButtonOnClick = this.changeButtonOnClick.bind(this);
    this.cancelButtonOnClick = this.cancelButtonOnClick.bind(this);
  }

  componentDidMount() {
    eventBus.addEventListener(
      "RequestCompanyChange",
      this.onRequestCompanyChange.bind(this)
    );
  }

  onRequestCompanyChange(data: any) {
    if (!data) {
      data = {
        allowClose: true,
      };
    }
    this.setState({
      popupAllowClose: data.allowClose === true,
      popupIsOpened: true,
      popupSelectedCompany: this.props.currentCompany,
    });
  }

  selectCompanyOnClick(e: React.MouseEvent) {
    let target: HTMLElement | null = e.target as HTMLElement,
      company_id = target.getAttribute("data-company-id"),
      company = company_id
        ? companyEntityAdapter
            .getSelectors()
            .selectById(store.getState().company, company_id)
        : undefined,
      self = this;

    if (!company) {
      return;
    }

    this.setState(
      {
        popupSelectedCompany: company,
      },
      function () {
        if (!self.state.popupAllowClose) {
          self.changeButtonOnClick();
        }
      }
    );
  }

  changeButtonOnClick() {
    store.dispatch(
      setCurrentCompany(
        new SetCurrentCompanyPayload(this.state.popupSelectedCompany)
      )
    );
    this.setState({
      popupIsOpened: false,
    });
  }

  cancelButtonOnClick() {
    this.setState({
      popupIsOpened: false,
    });
  }

  render() {
    let self = this,
      content;

    if (!this.props.companys || this.props.companys.length === 0) {
      content = (
        <div>
          <div className="wait-message">
            <div className="wait-animation"></div>
            Een ogenblik geduld aub...
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          {this.state.popupAllowClose ? (
            <p>Selecteer hieronder het bedrijf waarmee je aan de slag wilt:</p>
          ) : (
            <p>Selecteer hieronder het bedrijf om aan de slag te gaan:</p>
          )}
          <div className="selector-box">
            {this.props.companys?.map(function (company, index) {
              let buttonClass = "button";
              if (
                self.state.popupSelectedCompany &&
                self.state.popupSelectedCompany.id === company.id
              ) {
                buttonClass += " selected";
              }

              return (
                <div className="company selector-btn" key={index}>
                  <button
                    className={buttonClass}
                    onClick={self.selectCompanyOnClick}
                    data-company-id={company.id}
                  >
                    {company.name}
                  </button>
                </div>
              );
            })}
          </div>
          {this.state.popupAllowClose ? (
            <div className="popup-buttons">
              <button
                className="button secondary"
                onClick={this.changeButtonOnClick}
              >
                Wijzig bedrijf
              </button>
              <button className="button" onClick={this.cancelButtonOnClick}>
                Annuleren
              </button>
            </div>
          ) : (
            <div className="popup-buttons">
              <button
                className="button secondary"
                onClick={this.changeButtonOnClick}
              >
                Selecteer bedrijf
              </button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="company-selector-container">
        <Popup
          title="Selecteer bedrijf"
          isOpened={this.state.popupIsOpened}
          showCloseButton={this.state.popupAllowClose}
          closeCallback={this.cancelButtonOnClick}
        >
          {content}
        </Popup>
      </div>
    );
  }
}

export default connect(mapState, {})(CompanySelector);
