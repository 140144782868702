import React, { Component } from "react";
import DefaultPageLayout from "../DefaultPageLayout/DefaultPageLayout";
import MoreInfoButton from "../../Component/Popup/MoreInfoButton";
import DashboardProgress from "../../Component/DasboardProgress/DashboardProgress";
import "./Dashboard.scss";
import { connect } from "react-redux";
import { User } from "../../Redux/reducer/user/slice";
import moment from "moment";
import {
  LocationProgress,
  Progress,
  selectLocationProgressByLocation,
  selectPluginIdByLastActivity,
} from "../../Redux/reducer/progress/slice";
import {
  selectTaskById,
  selectTaskByProgressPluginId,
  Task,
} from "../../Redux/reducer/task/task";
import { NavLink } from "react-router-dom";
import {
  Company,
  CompanysLoadingStatus,
} from "../../Redux/reducer/company/slice";
import AdvisorContactBlock from "../../Component/ContactBlock/AdvisorContactBlock";
import {
  Location,
  LocationsLoadingStatus,
} from "../../Redux/reducer/location/slice";
import WaitDefaultPageLayout from "../DefaultPageLayout/WaitDefaultPageLayout";
import { getRandomQuote } from "../Quotes";
import Blockquote from "../../Component/Quote/Quote";

interface DashboardProps {
  companyLoadingStatus: CompanysLoadingStatus;
  locationLoadingStatus: LocationsLoadingStatus;
  user?: User;
  locationProgress?: LocationProgress;
  currentOrNextTask?: Task;
  currentOrNextTaskProgress?: Progress;
  lastActivityTask?: Task;
  currentCompany?: Company;
  currentLocation?: Location;
}

function mapState(state: any) {
  let location = state.location.currentLocation,
    company = state.company.currentCompany,
    locationProgress =
      company && location
        ? selectLocationProgressByLocation(state, company, location)
        : undefined,
    currentOrNextTask =
      company && locationProgress && locationProgress.nextTaskPluginId
        ? selectTaskById(locationProgress.nextTaskPluginId, company)
        : undefined,
    currentOrNextTaskProgress =
      locationProgress && currentOrNextTask
        ? locationProgress.progress[currentOrNextTask.id]
        : undefined,
    lastActivityProgressPluginId =
      company && location
        ? selectPluginIdByLastActivity(state, company, location)
        : undefined,
    lastActivityTask = lastActivityProgressPluginId
      ? selectTaskByProgressPluginId(lastActivityProgressPluginId, company)
      : undefined;

  return {
    companyLoadingStatus: state.company.status,
    locationLoadingStatus: state.location.status,
    user: state.user.user,
    locationProgress: locationProgress,
    currentOrNextTask: currentOrNextTask,
    currentOrNextTaskProgress: currentOrNextTaskProgress,
    lastActivityTask: lastActivityTask,
    currentCompany: company,
    currentLocation: location,
  };
}

class Dashboard extends Component<DashboardProps, {}> {
  private quotes: Array<string> = [
    "Een complimentje zorgt voor werkgeluk. Heb jij je collega's al een compliment gegeven?",
    "Wees niet bang om te falen. Je hoeft het maar één keer bij het juiste eind te hebben.",
    "Wie onvervangbaar wil zijn, moet zich blijvend onderscheiden.",
    "De enige wijsheid die echt telt is weten dat je niets weet.",
    "Een pessimist ziet in elke kans de moeilijkheden. Een optimist ziet in elke moeilijkheid de kansen.",
    "Successen vieren mag, maar nog veel belangrijker is het om van jouw fouten te leren.",
  ];

  render() {
    let is_registered_today = true,
      quote = getRandomQuote();

    if (!this.props.user || !this.props.user.firstLogin) {
      return (
        <DefaultPageLayout title="Dashboard">
          <div className="wait-message">
            <div className="wait-animation"></div>
          </div>
        </DefaultPageLayout>
      );
    }

    if (this.props.user.firstLogin) {
      is_registered_today = !moment(this.props.user.firstLogin).isBefore(
        moment(),
        "day"
      );
    }

    if (!this.props.currentCompany) {
      if (this.props.companyLoadingStatus !== CompanysLoadingStatus.Succeeded) {
        return <WaitDefaultPageLayout />;
      }

      return <WaitDefaultPageLayout />;
    }
    if (!this.props.currentLocation) {
      if (
        this.props.locationLoadingStatus !== LocationsLoadingStatus.Succeeded
      ) {
        return <WaitDefaultPageLayout />;
      }

      return <WaitDefaultPageLayout />;
    }

    return (
      <DefaultPageLayout title="Dashboard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="dashboard-top">
                <h2 className="sub-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.515"
                    height="24.343"
                    viewBox="0 0 36.515 24.343"
                  >
                    <g transform="translate(0 -85.339)">
                      <g transform="translate(0 85.339)">
                        <g transform="translate(0 0)">
                          <path
                            d="M217.127,199.708v-6.955a.761.761,0,0,0-1.522,0v6.955a3.043,3.043,0,1,0,1.522,0Zm-.761,4.456a1.521,1.521,0,1,1,1.521-1.521A1.521,1.521,0,0,1,216.367,204.165Z"
                            transform="translate(-198.109 -184.387)"
                          />
                          <path
                            d="M246.094,121.89a.761.761,0,0,0,.761-.761v-3.043a.761.761,0,1,0-1.522,0v3.043A.761.761,0,0,0,246.094,121.89Z"
                            transform="translate(-227.836 -115.045)"
                          />
                          <path
                            d="M416,331.421a.761.761,0,0,0,.761.761H419.8a.761.761,0,0,0,0-1.522h-3.043A.761.761,0,0,0,416,331.421Z"
                            transform="translate(-386.331 -313.164)"
                          />
                          <path
                            d="M35.8,330.66H32.761a.761.761,0,0,0,0,1.522H35.8a.761.761,0,1,0,0-1.522Z"
                            transform="translate(-29.718 -313.164)"
                          />
                          <path
                            d="M142.25,148.643a.761.761,0,1,0-1.311.773l1.293,2.2a.761.761,0,0,0,1.311-.773Z"
                            transform="translate(-130.79 -143.78)"
                          />
                          <path
                            d="M394.946,225.427a.761.761,0,0,0,1.036.289l2.245-1.267a.761.761,0,1,0-.725-1.338l-.022.012-2.245,1.267A.761.761,0,0,0,394.946,225.427Z"
                            transform="translate(-366.688 -213.2)"
                          />
                          <path
                            d="M67.575,224.38h0l-2.246-1.267a.761.761,0,1,0-.769,1.313l.022.012,2.245,1.267a.761.761,0,0,0,.747-1.325Z"
                            transform="translate(-59.607 -213.19)"
                          />
                          <path
                            d="M334.087,148.384l0,0a.761.761,0,0,0-1.041.269l-1.293,2.2a.761.761,0,0,0,1.311.775l1.293-2.2A.761.761,0,0,0,334.087,148.384Z"
                            transform="translate(-307.99 -143.788)"
                          />
                          <path
                            d="M31.131,90.538A18.336,18.336,0,0,0,0,103.571v4.081a2.046,2.046,0,0,0,2.054,2.03H34.461a2.046,2.046,0,0,0,2.054-2.029v-4.437A17.575,17.575,0,0,0,31.131,90.538Zm3.863,17.117a.523.523,0,0,1-.533.507H2.054a.523.523,0,0,1-.533-.507v-4.084A16.814,16.814,0,0,1,30.069,91.627a16.066,16.066,0,0,1,4.925,11.588v4.439Z"
                            transform="translate(0 -85.339)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                  Dashboard
                </h2>
                {is_registered_today ? (
                  <div>
                    <h1 className="title">
                      Welkom, {this.props.user.givenName}
                    </h1>
                    <p>
                      Je bent in het overzicht van Mijn Stigas. Hier kun je alle
                      taken toevoegen om jouw organisatie veilig en vitaal te
                      houden.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h1 className="title">
                      Welkom terug, {this.props.user.givenName}
                    </h1>
                    {this.props.lastActivityTask !== undefined ? (
                      <p>
                        Tijdens jouw laatste bezoek was je bezig met het
                        onderdeel{" "}
                        <NavLink to={this.props.lastActivityTask.url}>
                          {this.props.lastActivityTask.title}
                        </NavLink>
                        . Hieronder kan je de voortgang zien van jouw taken.
                      </p>
                    ) : (
                      <p>Hieronder kan je de voortgang zien van jouw taken.</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container box-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="box dashboard-progress-box">
                <div className="box-top">
                  <h2>Voortgang</h2>
                  <MoreInfoButton title="Voortgang Mijn Stigas-taken">
                    <p>
                      Hier kun je je voorlopige voortgang zien van je huidige
                      taken. Probeer alle taken af te ronden om jouw organisatie
                      veilig en gezond te houden.
                    </p>
                    <p>
                      Indien er bij een taak 'Ga verder' staat, kan je deze taak
                      eenvoudig oppakken door hier op te klikken.
                    </p>
                  </MoreInfoButton>
                </div>
                <DashboardProgress />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="box stacked">
                {this.props.locationProgress ? (
                  <div className="box box-cta">
                    {this.props.currentOrNextTask === undefined ? (
                      <div>
                        <div className="box-top">
                          <h2>Klaar</h2>
                        </div>
                        <div>
                          <p>Je hebt alle taken afgerond.</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="box-top">
                          <h2>Volgende stap naar 100%</h2>
                        </div>
                        <div>
                          {this.props.currentOrNextTaskProgress === undefined ||
                          this.props.currentOrNextTaskProgress.percentage ===
                            0 ? (
                            <div>
                              <p>
                                Ga aan de slag met{" "}
                                {this.props.currentOrNextTask.title}.
                              </p>
                              <NavLink
                                to={this.props.currentOrNextTask.url}
                                className="button"
                              >
                                Aan de slag
                              </NavLink>
                            </div>
                          ) : (
                            <div>
                              <p>
                                Je hebt als laatste de taak{" "}
                                {this.props.currentOrNextTask.title} ingevuld.
                                Wil je hiermee verder gaan?
                              </p>
                              <NavLink
                                to={this.props.currentOrNextTask.url}
                                className="button"
                              >
                                Ga verder
                              </NavLink>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="box box-cta">
                    <div className="wait-message">
                      <div className="wait-animation"></div>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                )}
                {quote ? (
                  <div className="box inspiration">
                    <div className="box-top">
                      <h2>Inspiratie</h2>
                    </div>
                    <Blockquote quote={quote} icon={false} margins={false} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="box">
                <AdvisorContactBlock
                  advisor={
                    this.props.currentLocation.advisor.length > 0
                      ? this.props.currentLocation.advisor[0]
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </DefaultPageLayout>
    );
  }
}

export default connect(mapState, {})(Dashboard);
