import {
  createAsyncThunk,
  createSlice,
  EntityState,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import Client from "../../../Client/Client";
import { JsonApiEntity } from "../JsonApiEntity";
import { Location } from "../location/slice";

export interface Premed extends JsonApiEntity {
  name?: string;
  since?: string;
  elearning_date?: string;
  location_id: string;
}

export function newPremed(location_id: string): Premed {
  return {
    id: "",
    type: "premed--premed",
    created: "",
    changed: "",
    name: undefined,
    since: undefined,
    elearning_date: undefined,
    location_id: location_id,
  };
}

export enum FetchPremedsStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export const premedEntityAdapter = createEntityAdapter<Premed>();

export interface PremedState extends EntityState<Premed> {
  fetchPremedsStatus: FetchPremedsStatus;
  error: string | null;
  waitingForClientUpsertCounter: number;
}

export const initialState: PremedState = premedEntityAdapter.getInitialState({
  fetchPremedsStatus: FetchPremedsStatus.Idle,
  error: null,
  waitingForClientUpsertCounter: 0,
});

export const fetchPremeds = createAsyncThunk<
  Array<object>,
  Location,
  {
    extra: {
      client: Client;
    };
  }
>("premed/fetchPremeds", async function (arg: Location, thunkAPI) {
  const results = await thunkAPI.extra.client.findAllForLocation(
    arg,
    "premed--premed"
  );
  return results.data;
});

export const createPremed = createAsyncThunk<
  Premed,
  Premed,
  {
    extra: {
      client: Client;
    };
  }
>("premed/createPremed", async function (arg: Premed, thunkAPI) {
  const results = await thunkAPI.extra.client.create(arg);

  return results.data;
});

export const deletePremed = createAsyncThunk<
  string,
  Premed,
  {
    extra: {
      client: Client;
    };
  }
>("premed/deletePremed", async function (arg: Premed, thunkAPI) {
  await thunkAPI.extra.client.delete(arg);

  return arg.id;
});

export const updatePremed = createAsyncThunk<
  Premed,
  Premed,
  {
    extra: {
      client: Client;
    };
  }
>("premed/updatePremed", async function (arg: Premed, thunkAPI) {
  const results = await thunkAPI.extra.client.update(arg);

  return results.data;
});

function increaseWaitingForClientUpsertCounter(
  state: PremedState,
  action: any
) {
  state.waitingForClientUpsertCounter++;
}

function decreaseWaitingForClientUpsertCounter(
  state: PremedState,
  action: any
) {
  state.waitingForClientUpsertCounter--;
}

const slice = createSlice({
  name: "premed",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchPremeds.pending.toString()]: function (
      state: PremedState,
      action: any
    ) {
      state.fetchPremedsStatus = FetchPremedsStatus.Loading;
      premedEntityAdapter.removeAll(state);
    },
    [fetchPremeds.rejected.toString()]: function (
      state: PremedState,
      action: any
    ) {
      state.fetchPremedsStatus = FetchPremedsStatus.Failed;
      state.error = action.error.message;
    },
    [fetchPremeds.fulfilled.toString()]: function (
      state: PremedState,
      action: any
    ) {
      state.fetchPremedsStatus = FetchPremedsStatus.Succeeded;
      premedEntityAdapter.upsertMany(state, action.payload);
    },

    [createPremed.pending.toString()]: increaseWaitingForClientUpsertCounter,
    [createPremed.rejected.toString()]: decreaseWaitingForClientUpsertCounter,
    [createPremed.fulfilled.toString()]: function (
      state: PremedState,
      action: any
    ) {
      premedEntityAdapter.addOne(state, action.payload);
      decreaseWaitingForClientUpsertCounter(state, action);
    },

    [deletePremed.fulfilled.toString()]: function (
      state: PremedState,
      action: any
    ) {
      premedEntityAdapter.removeOne(state, action.payload);
    },

    [updatePremed.pending.toString()]: increaseWaitingForClientUpsertCounter,
    [updatePremed.rejected.toString()]: decreaseWaitingForClientUpsertCounter,
    [updatePremed.fulfilled.toString()]: function (
      state: PremedState,
      action: any
    ) {
      premedEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      decreaseWaitingForClientUpsertCounter(state, action);
    },
  },
});

export function selectAllPremedsByLocation(
  state: any,
  location: Location
): Array<Premed> {
  return premedEntityAdapter
    .getSelectors()
    .selectAll(state.premed)
    .filter(function (premed: Premed) {
      return premed.location_id === location.id;
    });
}

export default slice.reducer;
