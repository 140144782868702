import { JsonApiModel } from "../JsonApiEntity";
import { newSector } from "./slice";

function deserializer(sectorData: any, included: any, jsonApi: any) {
  let sector = newSector();

  sector.id = sectorData.id;
  sector.created = sectorData.attributes.created;
  sector.changed = sectorData.attributes.changed;
  sector.sector_id = sectorData.attributes.sector_id;
  sector.name = sectorData.attributes.name;

  return sector;
}

const jsonApiModel: JsonApiModel = {
  name: "sector--sector",
  constructor: {
    sector_id: "",
    name: "",
  },
  options: {
    collectionPath: "sector/sector",
    deserializer: deserializer,
  },
};

export default jsonApiModel;
