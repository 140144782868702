import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../Page/Dashboard/Dashboard";
import Login from "../Page/User/Login";
import LoginCompleted from "../Page/User/LoginCompleted";
import Logout from "../Page/User/Logout";
import NotFound404 from "../Page/NotFound404/NotFound404";
import { connect } from "react-redux";
import { Company } from "../Redux/reducer/company/slice";
import WaitDefaultPageLayout from "../Page/DefaultPageLayout/WaitDefaultPageLayout";
import { Location } from "../Redux/reducer/location/slice";

const CompanyReset = React.lazy(() => import("../Page/Company/Reset"));

const VeiligGezondWerken = React.lazy(
  () => import("../Page/Subject/VeiligGezondWerken/VeiligGezondWerken")
);
const VeiligGezondWerkenBhv = React.lazy(
  () => import("../Page/Subject/VeiligGezondWerken/Task/Bhv")
);
const VeiligGezondWerkenPremed = React.lazy(
  () => import("../Page/Subject/VeiligGezondWerken/Task/Premed")
);
const VeiligGezondWerkenSafeworkinstruction = React.lazy(
  () => import("../Page/Subject/VeiligGezondWerken/Task/Safeworkinstruction")
);
const VeiligGezondWerkenRie = React.lazy(
  () => import("../Page/Subject/VeiligGezondWerken/Task/Rie")
);
const VeiligGezondWerkenAccident = React.lazy(
  () => import("../Page/Subject/VeiligGezondWerken/Task/Accident")
);
const VeiligGezondWerkenArboContract = React.lazy(
  () => import("../Page/Subject/VeiligGezondWerken/Task/ArboContract")
);
const Administratie = React.lazy(
  () => import("../Page/Subject/Administratie/Administratie")
);
const AdministratieProfile = React.lazy(
  () => import("../Page/Subject/Administratie/Task/Profile")
);

const Styling = React.lazy(() => import("../Page/Styling/Styling"));

type AppRouterProps = {
  currentCompany?: Company;
  totalCompanyCount: number;
  currentLocation?: Location;
  totalLocationCount: number;
};

function mapState(state: any) {
  return {
    currentCompany: state.company.currentCompany,
    totalCompanyCount: state.company.ids.length,
    currentLocation: state.location.currentLocation,
    totalLocationCount: state.location.ids.length,
  };
}

const subjectAndTaskToComponentMapping: any = {
  veiliggezondwerken: VeiligGezondWerken,
  veiliggezondwerken_bhv: VeiligGezondWerkenBhv,
  veiliggezondwerken_premed: VeiligGezondWerkenPremed,
  veiliggezondwerken_safeworkinstruction: VeiligGezondWerkenSafeworkinstruction,
  veiliggezondwerken_rie: VeiligGezondWerkenRie,
  veiliggezondwerken_accident: VeiligGezondWerkenAccident,
  veiliggezondwerken_arbocontract: VeiligGezondWerkenArboContract,
  administratie: Administratie,
  administratie_profile: AdministratieProfile,
};

class AppRouter extends Component<AppRouterProps, any> {
  render() {
    let redirect_to_company_select_if_needed: any = null,
      redirect_to_location_select_if_needed: any = null;

    return (
      <Router basename="/">
        <Suspense fallback={<WaitDefaultPageLayout />}>
          <Switch>
            <Route path="/styling">
              <Styling />
            </Route>

            <PrivateRoute exact path="/">
              <Dashboard />
            </PrivateRoute>

            <Route exact path="/inloggen">
              <Login />
            </Route>
            <Route exact path="/inloggen/voltooid">
              <LoginCompleted />
            </Route>

            <PrivateRoute exact path="/uitloggen">
              <Logout />
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/company/reset"
              requiredUserPermissions={["allow company reset"]}
            >
              <CompanyReset />
            </PrivateRoute>
            {
              // Subject routes START
            }
            {this.props.currentCompany?.subject.map(function (subject, index) {
              let TagName = subjectAndTaskToComponentMapping[subject.id];
              if (!TagName) {
                console.log(
                  TagName + " does not exist for subject " + subject.id
                );
                return null;
              }
              return (
                <PrivateRoute key={index} exact path={subject.url}>
                  <TagName />
                </PrivateRoute>
              );
            })}

            {
              // Subject routes END
              // TASK routes START
            }
            {this.props.currentCompany?.task.map(function (task, index) {
              let TagName = subjectAndTaskToComponentMapping[task.id];

              if (!TagName) {
                console.log(TagName + " does not exist for task " + task.id);
                return null;
              }

              return (
                <PrivateRoute key={index} exact path={task.url}>
                  <TagName />
                </PrivateRoute>
              );
            })}
            {
              // TASK routes END
            }

            <Route path="*">
              <NotFound404 />
            </Route>
          </Switch>
        </Suspense>
        {redirect_to_company_select_if_needed}
        {redirect_to_location_select_if_needed}
      </Router>
    );
  }
}

export default connect(mapState, {})(AppRouter);
