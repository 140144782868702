import React, { Component } from "react";
import DefaultPageLayout from "./DefaultPageLayout";

export default class WaitDefaultPageLayout extends Component<any, any> {
  render() {
    return (
      <DefaultPageLayout
        title="Een ogenblik geduld aub..."
        preventUpdatingDocumentTitle={true}
      >
        <div className="wait-message">
          <div className="wait-animation"></div>
          Een ogenblik geduld aub...
        </div>
      </DefaultPageLayout>
    );
  }
}
