import React, { Component } from "react";

import "./ContactBlock.scss";

interface ContactBlockProps {
  title: string;
  image_url?: string;
  size?: string;
}

export default class ContactBlock extends Component<ContactBlockProps, {}> {
  render() {
    return (
      <div className={"contact-block " + this.props.size || ""}>
        {this.props.image_url ? (
          <div className="contact-photo">
            <img src={this.props.image_url} alt="Contact" />
          </div>
        ) : null}
        <div className="contact-info">
          <h3 className="contact-title">{this.props.title}</h3>
          {this.props.children}
        </div>
      </div>
    );
  }
}
