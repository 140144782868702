let numberOfPendingRequests: number = 0;

export function pendingRequestInterceptor(config: any) {
  numberOfPendingRequests++;

  return config;
}

export function pendingRequestResponseInterceptor(response: any) {
  numberOfPendingRequests--;

  return response;
}

export function getNumberOfPendingRequests(): number {
  return numberOfPendingRequests;
}
