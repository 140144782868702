import {
  createAsyncThunk,
  createSlice,
  EntityState,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import Client from "../../../Client/Client";
import { JsonApiEntity } from "../JsonApiEntity";
import { Company } from "../company/slice";
import { Advisor } from "../advisor/slice";
import { Sector } from "../sector/slice";
import eventBus from "../../../App/eventBus";

export interface Location extends JsonApiEntity {
  name?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  employees?: number;
  contact?: undefined;
  email?: undefined;
  telephone?: undefined;
  fax?: undefined;
  description?: undefined;
  mijnStigasApiEditLink?: undefined;
  advisor: Array<Advisor>;
  mainSector?: Sector;
  relevantSector: Array<Sector>;
}

export function newLocation(): Location {
  return {
    id: "",
    type: "location--location",
    created: "",
    changed: "",
    name: undefined,
    address: undefined,
    zipcode: undefined,
    city: undefined,
    employees: undefined,
    contact: undefined,
    email: undefined,
    telephone: undefined,
    fax: undefined,
    description: undefined,
    mijnStigasApiEditLink: undefined,
    advisor: [],
    mainSector: undefined,
    relevantSector: [],
  };
}

export enum LocationsLoadingStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export const locationEntityAdapter = createEntityAdapter<Location>();

export interface LocationState extends EntityState<Location> {
  status: LocationsLoadingStatus;
  error: string | null;
  currentLocation?: Location;
}

export const initialState: LocationState = locationEntityAdapter.getInitialState(
  {
    status: LocationsLoadingStatus.Idle,
    error: null,
    currentLocation: undefined,
  }
);

export const fetchLocations = createAsyncThunk<
  Array<object>,
  Company | undefined,
  {
    extra: {
      client: Client;
    };
  }
>(
  "location/fetchLocations",
  async function (arg: Company | undefined, thunkAPI) {
    if (!arg) {
      return [];
    }
    const results = await thunkAPI.extra.client.findAllForCompany(
      arg,
      "location--location"
    );
    return results.data;
  }
);

export class SetCurrentLocationPayload {
  location?: Location;

  constructor(location?: Location) {
    this.location = location;
  }
}

function dispatchLocationChanged(location?: Location) {
  eventBus.dispatch("LocationChanged", location);
}

const slice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    setCurrentLocation(
      state,
      action: PayloadAction<SetCurrentLocationPayload>
    ) {
      state.currentLocation = action.payload.location;
      dispatchLocationChanged(state.currentLocation);
    },
  },
  extraReducers: {
    [fetchLocations.pending.toString()]: function (state, action) {
      state.status = LocationsLoadingStatus.Loading;
      locationEntityAdapter.removeAll(state);
      state.currentLocation = undefined;
      dispatchLocationChanged(state.currentLocation);
    },
    [fetchLocations.fulfilled.toString()]: function (state, action) {
      state.status = LocationsLoadingStatus.Succeeded;
      locationEntityAdapter.setAll(state, action.payload);

      // Set the first location as current.
      const selectors = locationEntityAdapter.getSelectors(),
        ids = selectors.selectIds(state);

      if (ids.length === 1) {
        state.currentLocation =
          selectors.selectById(state, ids[0]) || undefined;
      } else {
        state.currentLocation = undefined;
      }
      dispatchLocationChanged(state.currentLocation);
    },
    [fetchLocations.rejected.toString()]: function (state, action) {
      locationEntityAdapter.removeAll(state);
      state.status = LocationsLoadingStatus.Failed;
      state.error = action.error.message;
      state.currentLocation = undefined;
      dispatchLocationChanged(state.currentLocation);
    },
  },
});

export function selectAll(state: any): Array<Location> {
  return locationEntityAdapter.getSelectors().selectAll(state.location);
}

export const { setCurrentLocation } = slice.actions;
export default slice.reducer;
