import React, { Component } from "react";
import "./Cookiebar.scss";
import { connect } from "react-redux";
import {
  CookiebarState,
  updateAndSaveCookiebarState,
  UpdateCookiebarStatePayload,
  User,
} from "../../Redux/reducer/user/slice";
import store from "../../Redux/store";
import { getQueryParameter } from "../../App/getQueryParameter";

interface CookiebarProps {
  user?: User;
}

interface CookiebarComponentState {
  cookiebarForceShow?: boolean;
}

function mapState(state: any) {
  return {
    user: state.user.user,
  };
}

class Cookiebar extends Component<CookiebarProps, CookiebarComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      cookiebarForceShow: getQueryParameter("cookiebar-show") === "1",
    };

    this.acceptedButtonOnClick = this.acceptedButtonOnClick.bind(this);
    this.necessaryButtonOnClick = this.necessaryButtonOnClick.bind(this);
  }

  setCookiebarState(state: CookiebarState) {
    let updateCookiebarStatePayload = new UpdateCookiebarStatePayload(state);
    store.dispatch(updateAndSaveCookiebarState(updateCookiebarStatePayload));

    this.setState({
      cookiebarForceShow: false,
    });
  }

  acceptedButtonOnClick() {
    this.setCookiebarState(CookiebarState.Accepted);
  }

  necessaryButtonOnClick() {
    this.setCookiebarState(CookiebarState.Necessary);
  }

  render() {
    if (
      !this.props.user ||
      this.props.user.cookiebarState === CookiebarState.NotLoaded
    ) {
      // No user loaded yet or the cookiebar state is not loaded yet.
      return null;
    }

    if (
      !this.state.cookiebarForceShow &&
      this.props.user.cookiebarState !== CookiebarState.Undefined
    ) {
      // Choice is already made.
      return null;
    }

    return (
      <div className="cookiebar">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-12 content my-auto">
              <p>
                Als je cookies accepteert, kun je onze website makkelijker
                gebruiken, vind je sneller wat je zoekt en zie je meer relevante
                informatie.
              </p>
              <p>
                Wil je dat niet? Klik dan op 'Enkel noodzakelijk cookies'. Lees
                meer in onze{" "}
                <a
                  href="https://www.stigas.nl/cookiepolicy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  cookieverklaring
                </a>
                .
              </p>
            </div>
            <div className="col-md-3 col-sm-12 buttons">
              <button
                className="button secondary"
                onClick={this.acceptedButtonOnClick}
              >
                Accepteren
              </button>
              <button
                className="button secondary"
                onClick={this.necessaryButtonOnClick}
              >
                Enkel noodzakelijke cookies
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapState, {})(Cookiebar);
