import store from "../../Redux/store";
import {
  UpdatePermissionsPayLoad,
  updatePermissions,
} from "../../Redux/reducer/user/slice";

export const devour_client_user_permissions_middleware = {
  name: "user_permissions",
  res: function (payload: any) {
    if (!payload.res.data.meta || !payload.res.data.meta.user_permissions) {
      return payload;
    }

    let updateUserPermissionsPayload = new UpdatePermissionsPayLoad(
      payload.res.data.meta.user_permissions
    );

    store.dispatch(updatePermissions(updateUserPermissionsPayload));

    return payload;
  },
};
