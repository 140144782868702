import { combineReducers } from "redux";
import user from "./user/slice";
import company from "./company/slice";
import location from "./location/slice";
import progress from "./progress/slice";
import bhvperson from "./bhvperson/slice";
import premed from "./premed/slice";
import safeworkinstruction from "./safeworkinstruction/slice";
import arbocontract from "./arbocontract/slice";
import workbook from "./workbook/slice";
import advisor from "./advisor/slice";
import sector from "./sector/slice";

export default combineReducers({
  user,
  company,
  location,
  progress,
  bhvperson,
  premed,
  safeworkinstruction,
  arbocontract,
  workbook,
  advisor,
  sector,
});

export type RootState = ReturnType<typeof combineReducers>;
