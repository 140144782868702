import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteProps, Redirect } from "react-router-dom";
import WaitDialog from "../../Component/Popup/WaitDialog";
import { User } from "../../Redux/reducer/user/slice";
import { getQueryParameter } from "../../App/getQueryParameter";

interface LoginProps extends RouteProps {
  user?: User;
}

function mapState(state: any) {
  return {
    user: state.user.user,
  };
}

class LoginCompleted extends Component<LoginProps, {}> {
  render() {
    if (this.props.user) {
      const redirectToPathAfterLogin = getQueryParameter(
        "redirectToPathAfterLogin"
      );

      if (redirectToPathAfterLogin && redirectToPathAfterLogin.length > 0) {
        return <Redirect to={redirectToPathAfterLogin} />;
      } else {
        return <Redirect to="/" />;
      }
    }

    return (
      <WaitDialog isOpened={true}>
        <p>Een ogenblik geduld aub...</p>
      </WaitDialog>
    );
  }
}

export default connect(mapState, {})(LoginCompleted);
