import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import eventBus from "../../../App/eventBus";

export interface User {
  iamId: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
  firstLogin?: string;
  permissions: Array<string>;
  cookiebarState: CookiebarState;
}

export class LoginPayload {
  user: User;
  accessToken?: string;

  constructor(user: User, accessToken?: string) {
    this.user = user;
    this.accessToken = accessToken;
  }
}

export class UpdateAccessTokenPayload {
  accessToken?: string;

  constructor(accessToken?: string) {
    this.accessToken = accessToken;
  }
}

export class UpdateActivityPayLoad {
  firstLogin: string;

  constructor(firstLogin: string) {
    this.firstLogin = firstLogin;
  }
}

export class UpdatePermissionsPayLoad {
  permissions: Array<string>;

  constructor(permissions: Array<string>) {
    this.permissions = permissions;
  }
}

export enum CookiebarState {
  Necessary,
  Accepted,
  Undefined,
  NotLoaded,
}

export class UpdateCookiebarStatePayload {
  state: CookiebarState;

  constructor(state: CookiebarState) {
    this.state = state;
  }
}

interface UserState {
  user?: User;
  accessToken?: String;
}

export const initialState: UserState = {};

const slice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayload>) {
      state.user = action.payload.user;
      state.user.name = String(
        state.user.givenName + " " + state.user.familyName
      ).trim();
      state.accessToken = action.payload.accessToken;

      dispatchUserActivityLoginEvent(undefined, undefined, state.user);
    },
    updateAccessToken(state, action: PayloadAction<UpdateAccessTokenPayload>) {
      state.accessToken = action.payload.accessToken;
    },
    updateActivity(state, action: PayloadAction<UpdateActivityPayLoad>) {
      if (!state.user) {
        console.warn("updateActivity called before state.user was set.");
        return;
      }
      state.user.firstLogin = action.payload.firstLogin;
    },
    updatePermissions(state, action: PayloadAction<UpdatePermissionsPayLoad>) {
      if (!state.user) {
        console.warn("updatePermissions called before state.user was set.");
        return;
      }
      state.user.permissions = action.payload.permissions;
    },
    updateCookiebarState(
      state,
      action: PayloadAction<UpdateCookiebarStatePayload>
    ) {
      if (!state.user) {
        console.warn("updateCookiebarState called before state.user was set.");
        return;
      }
      state.user.cookiebarState = action.payload.state;

      eventBus.dispatch("UserCookiebarStateUpdatedInReduxEvent", {
        state: state.user.cookiebarState,
      });
    },
    updateAndSaveCookiebarState(
      state,
      action: PayloadAction<UpdateCookiebarStatePayload>
    ) {
      if (!state.user) {
        console.warn("updateCookiebarState called before state.user was set.");
        return;
      }
      state.user.cookiebarState = action.payload.state;

      eventBus.dispatch("UserCookiebarStateUpdatedInReduxEvent", {
        state: state.user.cookiebarState,
      });

      eventBus.dispatch("UserCookiebarStateEvent", {
        state: action.payload.state,
      });
    },
  },
});

export function dispatchUserActivityLoginEvent(
  company_id?: string,
  location_id?: string,
  user?: User
) {
  eventBus.dispatch("UserActivityLoginEvent", {
    company_id: company_id,
    location_id: location_id,
    user: user,
  });
}

export const {
  login,
  updateAccessToken,
  updateActivity,
  updatePermissions,
  updateCookiebarState,
  updateAndSaveCookiebarState,
} = slice.actions;
export default slice.reducer;
