import store from "../../Redux/store";
import {
  UpdateActivityPayLoad,
  updateActivity,
} from "../../Redux/reducer/user/slice";

export const devour_client_user_activity_middleware = {
  name: "user_activity",
  res: function (payload: any) {
    if (!payload.res.data.meta || !payload.res.data.meta.user_activity) {
      return payload;
    }

    let updateUserActivityPayload = new UpdateActivityPayLoad(
      payload.res.data.meta.user_activity.first_login
    );

    store.dispatch(updateActivity(updateUserActivityPayload));

    return payload;
  },
};
