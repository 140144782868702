import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "../location/slice";
import { Company } from "../company/slice";
import Client from "../../../Client/Client";
import { getUpdateProgressPayloadFromMetaObject } from "../../../Client/middleware/getUpdateProgressPayloadFromMetaObject";

export interface CompanyProgress {
  company_id: string;
  location: Array<LocationProgress>;
}

export interface LocationProgress {
  location_id: string;
  progress: any;
  nextTaskPluginId?: string;
}

export interface Progress {
  location_id: string;
  plugin_id: string;
  activated: boolean;
  completed: boolean;
  percentage: number;
  group: Array<string>;
  notification: Array<Notification>;
  lastActivity?: number;
}

export enum NotificationSeverity {
  Info = "info",
  Alert = "alert",
}

export interface Notification {
  id: string;
  severity: NotificationSeverity;
  summary: string;
}

interface ProgressState {
  company: Array<CompanyProgress>;
}

export class UpdateProgressPayload {
  company: Array<CompanyProgress>;

  constructor(company: Array<CompanyProgress>) {
    this.company = company;
  }
}

export const initialState: ProgressState = {
  company: [],
};

export const fetchProgress = createAsyncThunk<
  Array<object>,
  void,
  {
    extra: {
      client: Client;
    };
  }
>("progress/fetchProgress", async function (arg: void, thunkAPI) {
  const results = await thunkAPI.extra.client.updateProgress();
  return results.data;
});

const progresslice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    updateAllLocationsProgress(
      state,
      action: PayloadAction<UpdateProgressPayload>
    ) {
      state.company = action.payload.company;
    },
  },
  extraReducers: {
    [fetchProgress.fulfilled.toString()]: function (state, action) {
      let payload = getUpdateProgressPayloadFromMetaObject(action.payload.meta);
      state.company = payload.company;
    },
  },
});

export function selectLocationProgressByLocation(
  state: any,
  company: Company,
  location: Location
): LocationProgress | undefined {
  if (state.progress === undefined) {
    return undefined;
  }

  for (let i = 0; i < state.progress.company.length; i++) {
    if (state.progress.company[i].company_id === company.id) {
      for (let j = 0; j < state.progress.company[i].location.length; j++) {
        if (state.progress.company[i].location[j].location_id === location.id) {
          return state.progress.company[i].location[j];
        }
      }
    }
  }

  return undefined;
}

export function selectLocationProgress(
  state: any,
  company: Company,
  location: Location,
  plugin_id: string
): Progress | undefined {
  let locationProgress = selectLocationProgressByLocation(
    state,
    company,
    location
  );

  return locationProgress && locationProgress.progress[plugin_id] !== undefined
    ? locationProgress.progress[plugin_id]
    : undefined;
}

export function selectLocationProgressByGroup(
  state: any,
  company: Company,
  location: Location,
  group: string
): Array<Progress> {
  let locationProgress = selectLocationProgressByLocation(
      state,
      company,
      location
    ),
    all: any = {},
    i;

  if (!locationProgress) {
    return [];
  }

  for (i in locationProgress.progress) {
    if (!locationProgress.progress.hasOwnProperty(i)) {
      continue;
    }

    if (locationProgress.progress[i].group.indexOf(group) >= 0) {
      all[i] = locationProgress.progress[i];
    }
  }

  return all;
}

export function selectPluginIdByLastActivity(
  state: any,
  company: Company,
  location: Location
): string | undefined {
  let locationProgress = selectLocationProgressByLocation(
      state,
      company,
      location
    ),
    i,
    lastActivity = undefined,
    plugin_id = undefined;

  if (!locationProgress) {
    return undefined;
  }

  for (i in locationProgress.progress) {
    if (!locationProgress.progress.hasOwnProperty(i)) {
      continue;
    }

    if (locationProgress.progress[i].plugin_id === "total") {
      continue;
    }

    if (
      lastActivity === undefined ||
      lastActivity < locationProgress.progress[i].lastActivity
    ) {
      lastActivity = locationProgress.progress[i].lastActivity;
      plugin_id = locationProgress.progress[i].plugin_id;
    }
  }

  return plugin_id;
}

export const { updateAllLocationsProgress } = progresslice.actions;
export default progresslice.reducer;
