import eventBus from "./eventBus";
import Cookies from "universal-cookie";
import { CookiebarState } from "../Redux/reducer/user/slice";

let current_email_address: string = "",
  win = window as any,
  cookies = new Cookies();

eventBus.addEventListener("UserActivityLoginEvent", function (event: any) {
  if (win.dataLayer && event.user) {
    if (current_email_address !== event.user.email) {
      // Set the e-mail address in the datalayer.
      win.dataLayer.push({
        event: "EmailadresGebruikerBekend",
        Emailadres: event.user.email,
      });
      current_email_address = event.user.email;
    }
  } else {
    console.info("window.dataLayer not present loaded or user is undefined.");
  }
});

eventBus.addEventListener("UserCookiebarStateEvent", function (event: any) {
  if (win.dataLayer && event.state) {
    win.dataLayer.push({ event: "Cookie_Interaction", value: event.state });
  }
});

eventBus.addEventListener(
  "UserCookiebarStateUpdatedInReduxEvent",
  function (event: any) {
    let expires = new Date();
    expires.setDate(expires.getDate() + 182);

    switch (event.state) {
      case CookiebarState.Accepted:
      case CookiebarState.Necessary:
        cookies.set("cookiebar_state", event.state, {
          path: "/",
          expires: expires,
        });
        break;
      case CookiebarState.Undefined:
        cookies.remove("cookiebar_state", {
          path: "/",
        });
        break;
    }
  }
);
