import React, { Component, CSSProperties, MutableRefObject } from "react";
import "./Popup.scss";
import CloseButton from "./CloseButton";

type PopupProps = {
  id?: string;
  title: string;
  subTitle?: string;
  showCloseButton?: boolean;
  isOpened: boolean;
  closeCallback?: Function;
};

let zindex_counter = 1000;

export default class Popup extends Component<PopupProps, {}> {
  protected windowOnResizeTimeout: any;
  protected ref: MutableRefObject<HTMLDivElement | null>;

  constructor(props: any) {
    super(props);

    this.ref = React.createRef();

    this.windowOnResize = this.windowOnResize.bind(this);
    this.windowOnResizeOnTimeout = this.windowOnResizeOnTimeout.bind(this);
  }

  private handleBackdropOnClick(e: React.MouseEvent) {
    e.preventDefault();
    if (this.props.showCloseButton) {
      this.close();
    }
  }

  close() {
    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  }

  windowOnResize() {
    clearTimeout(this.windowOnResizeTimeout);
    this.windowOnResizeTimeout = setTimeout(this.windowOnResizeOnTimeout, 500);
  }

  windowOnResizeOnTimeout() {
    if (!this.ref.current) {
      return;
    }

    if (
      this.ref.current?.offsetHeight + this.ref.current?.offsetTop >
      window.innerHeight
    ) {
      this.ref.current?.classList.add("content-larger-than-viewport");
    } else {
      this.ref.current?.classList.remove("content-larger-than-viewport");
    }
  }

  componentDidUpdate(
    prevProps: Readonly<PopupProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (this.props.isOpened && !prevProps.isOpened) {
      window.addEventListener("resize", this.windowOnResize, true);
      this.windowOnResize();
    }
    if (!this.props.isOpened && prevProps.isOpened) {
      window.removeEventListener("resize", this.windowOnResize, true);
    }
  }

  render() {
    if (this.props.isOpened) {
      let backdrop_styles: CSSProperties = {
          zIndex: zindex_counter++,
        },
        popup_styles = {
          zIndex: zindex_counter++,
        };

      return (
        <div className="popup-container" id={this.props.id || ""}>
          <div
            className="popup-backdrop Popup"
            onClick={this.handleBackdropOnClick.bind(this)}
            style={backdrop_styles}
          ></div>
          <div className="popup" style={popup_styles} ref={this.ref}>
            {this.props.showCloseButton ? <CloseButton popup={this} /> : ""}
            {this.props.subTitle ? (
              <h3 className="popup-subtitle">{this.props.subTitle}</h3>
            ) : null}
            <h2 className="popup-title">{this.props.title}</h2>

            {this.props.children}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
