import React, { Component } from "react";

export interface Quote {
  text: string;
  author?: string;
  company?: string;
}

interface QuoteProps {
  quote: Quote;
  icon: boolean;
  margins: boolean;
}

export default class Blockquote extends Component<QuoteProps, any> {
  render() {
    let className = "blockquote";
    if (!this.props.icon) {
      className += " no-icon";
    }
    if (!this.props.margins) {
      className += " no-margins";
    }

    return (
      <blockquote className={className}>
        <p>{this.props.quote.text}</p>
        {this.props.quote.author ? (
          <span className="author">{this.props.quote.author}</span>
        ) : null}
        {this.props.quote.company ? (
          <span className="company">{this.props.quote.company}</span>
        ) : null}
      </blockquote>
    );
  }
}
