import { JsonApiModel } from "../JsonApiEntity";
import { newAdvisor } from "./slice";

function deserializer(advisorData: any, included: any, jsonApi: any) {
  let photo_data = advisorData.relationships.photo.data;

  let advisor = newAdvisor();

  advisor.id = advisorData.id;
  advisor.created = advisorData.attributes.created;
  advisor.changed = advisorData.attributes.changed;
  advisor.name = advisorData.attributes.name;
  advisor.email = advisorData.attributes.email;
  advisor.telephone = advisorData.attributes.telephone;
  advisor.role = advisorData.attributes.role;

  if (photo_data && included) {
    // Photo file linked.
    advisor.photo = jsonApi.deserialize.collection
      .call(jsonApi, included)
      .find(function (included: any) {
        return (
          included.type === photo_data.type && included.id === photo_data.id
        );
      });
  }

  return advisor;
}

const jsonApiModel: JsonApiModel = {
  name: "advisor--advisor",
  constructor: {
    name: "",
    email: "",
    telephone: "",
    role: "",
    photo: {
      jsonApi: "hasOne",
      type: "file--file",
    },
  },
  options: {
    collectionPath: "advisor/advisor",
    deserializer: deserializer,
  },
  includeParams: ["photo"],
};

export default jsonApiModel;
