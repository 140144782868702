import React, { Component } from "react";
import DefaultPageLayout from "../DefaultPageLayout/DefaultPageLayout";
import { connect } from "react-redux";
import { User } from "../../Redux/reducer/user/slice";
import Login from "../User/Login";

type NotFound404Props = {
  user?: User;
};

function mapState(state: any) {
  return {
    user: state.user.user,
  };
}

class NotFound404 extends Component<NotFound404Props, {}> {
  render() {
    if (!this.props.user) {
      // Login first before showing the 404 page.
      return (
        <Login
          redirectToPathAfterLogin={
            window.location.pathname + window.location.search
          }
        />
      );
    }

    return (
      <DefaultPageLayout title="Pagina niet gevonden">
        <h1>Pagina niet gevonden</h1>
        <p>De door u opgrevraagde pagina bestaat niet.</p>
      </DefaultPageLayout>
    );
  }
}

export default connect(mapState, {})(NotFound404);
