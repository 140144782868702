import { Company } from "../company/slice";

export interface Subject {
  id: string;
  progress_plugin_id: string;
  title: string;
  path: string;
  weight: number;
  url: string;
}

export function selectSubjectById(id: string, company: Company) {
  for (let i = 0; i < company.subject.length; i++) {
    if (company.subject[i].id === id) {
      return company.subject[i];
    }
  }
  return undefined;
}

export function getSubjectUrl(subject: Subject) {
  return "/" + subject.path;
}
