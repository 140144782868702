import React, { Component } from "react";
import "./Popup.scss";
import ReactDOM from "react-dom";

type ConfirmDialogProps = {
  title?: string;
  isOpened: boolean;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  confirmCallback?: Function;
  cancelCallback?: Function;
};

export default class ConfirmDialog extends Component<ConfirmDialogProps, {}> {
  private handleConfirmOnClick(e: React.MouseEvent) {
    e.preventDefault();
    if (this.props.confirmCallback) {
      this.props.confirmCallback();
    }
  }

  private handleCancelOnClick(e: React.MouseEvent) {
    e.preventDefault();
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }
  }

  render() {
    if (this.props.isOpened) {
      return (
        <div className="popup-container ConfirmDialog">
          <div className="popup-backdrop"></div>
          <div className="popup">
            <h2 className="popup-title">
              {this.props.title ? this.props.title : "Bevestig"}
            </h2>
            {this.props.children}
            <div className="popup-buttons">
              <button
                className="button secondary"
                onClick={this.handleConfirmOnClick.bind(this)}
                id="ConfirmDialog-confirm"
              >
                {this.props.confirmButtonTitle
                  ? this.props.confirmButtonTitle
                  : "OK"}
              </button>
              <button
                className="button tertiary"
                onClick={this.handleCancelOnClick.bind(this)}
                id="ConfirmDialog-cancel"
              >
                {this.props.cancelButtonTitle
                  ? this.props.cancelButtonTitle
                  : "Annuleren"}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  static show(
    message: any,
    confirmCallback?: Function,
    cancelCallback?: Function,
    title?: string,
    callbackPayload?: any
  ) {
    let container = document.createElement("div");
    document.body.appendChild(container);

    function destroy() {
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    }

    function handleOnConfirm() {
      destroy();

      if (confirmCallback) {
        confirmCallback(callbackPayload);
      }
    }

    function handleOnCancel() {
      destroy();

      if (cancelCallback) {
        cancelCallback(callbackPayload);
      }
    }

    ReactDOM.render(
      <ConfirmDialog
        title={title}
        isOpened={true}
        confirmCallback={handleOnConfirm}
        cancelCallback={handleOnCancel}
      >
        {typeof message === "string" ? <p>{message}</p> : message}
      </ConfirmDialog>,
      container
    );
  }
}
