import { JsonApiModel } from "../JsonApiEntity";
import moment from "moment";
import { Bhvperson, newBhvperson } from "./slice";

function serializer(bhvperson: Bhvperson) {
  let serialized: any = {
    type: bhvperson.type,
    attributes: {
      name: bhvperson.name,
      course_date: bhvperson.course_date
        ? moment(bhvperson.course_date).toISOString().split("T")[0]
        : null,
      course_expires_date: bhvperson.course_expires_date
        ? moment(bhvperson.course_expires_date).toISOString().split("T")[0]
        : null,
    },
    relationships: {
      location: {
        data: {
          id: bhvperson.location_id,
          type: "location--location",
        },
      },
      certificate: {
        data: bhvperson.certificate
          ? {
              id: bhvperson.certificate.id,
              type: bhvperson.certificate.type,
            }
          : null,
      },
    },
  };

  if (bhvperson.id) {
    serialized.id = bhvperson.id;
  }

  return serialized;
}

function deserializer(bhvpersonData: any, included: any, jsonApi: any) {
  let certificate_data = bhvpersonData.relationships.certificate.data;

  let bhvperson = newBhvperson(bhvpersonData.relationships.location.data.id);

  bhvperson.id = bhvpersonData.id;
  bhvperson.created = bhvpersonData.attributes.created;
  bhvperson.changed = bhvpersonData.attributes.changed;
  bhvperson.name = bhvpersonData.attributes.name;
  bhvperson.course_date = bhvpersonData.attributes.course_date;
  bhvperson.course_expires_date = bhvpersonData.attributes.course_expires_date;

  if (certificate_data && included) {
    // Certificate file linked.
    bhvperson.certificate = jsonApi.deserialize.collection
      .call(jsonApi, included)
      .find(function (included: any) {
        return (
          included.type === certificate_data.type &&
          included.id === certificate_data.id
        );
      });
  }

  bhvperson.initialCertificate = bhvperson.certificate;

  return bhvperson;
}

const jsonApiModel: JsonApiModel = {
  name: "bhvperson--bhvperson",
  constructor: {
    name: "",
    course_date: "",
    course_expires_date: "",
    location: {
      jsonApi: "hasOne",
      type: "location--location",
    },
    certificate: {
      jsonApi: "hasOne",
      type: "file--file",
    },
  },
  options: {
    collectionPath: "bhvperson/bhvperson",
    serializer: serializer,
    deserializer: deserializer,
  },
  includeParams: ["certificate"],
};

export default jsonApiModel;
