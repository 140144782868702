import React, { Component } from "react";
import "./scss/default-styling.scss";
import store from "./Redux/store";
import { Provider } from "react-redux";
import Manager from "./Keycloak/Manager";
import "./App/preloadOnUserChange.tsx";
import "./App/terminateOnMissingData.tsx";
import "react-dates/initialize";
import "moment/locale/nl";
import Client from "./Client/Client";
import AppRouter from "./Routing/AppRouter";
import "App/dataLayer";
import LocationSelector from "./App/LocationSelector";
import CompanySelector from "./App/CompanySelector";
import Cookiebar from "./Component/Cookiebar/Cookiebar";

export default class App extends Component<{}, {}> {
  private keycloakManager?: Manager;
  public static client: Client;

  componentDidMount() {
    this.keycloakManager = Manager.getInstance();
  }

  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <AppRouter />
          <CompanySelector />
          <LocationSelector />
          <Cookiebar />
        </Provider>
      </div>
    );
  }
}
