import { JsonApiModel } from "../JsonApiEntity";
import { Arbocontract, newArbocontract } from "./slice";

function booleanToOneZeroUndefined(value: any) {
  if (value === undefined) {
    return undefined;
  }
  return value ? "1" : 0;
}

function serializer(arbocontract: Arbocontract) {
  let serialized: any = {
    type: arbocontract.type,
    attributes: {
      has_arbocontract: arbocontract.has_arbocontract,
      supplier: arbocontract.supplier,
      has_employees: booleanToOneZeroUndefined(arbocontract.has_employees),
      expects_to_employ_or_has_temp: booleanToOneZeroUndefined(
        arbocontract.expects_to_employ_or_has_temp
      ),
      has_absenteeism_insurance: booleanToOneZeroUndefined(
        arbocontract.has_absenteeism_insurance
      ),
      has_separate_package_with_supplier: booleanToOneZeroUndefined(
        arbocontract.has_separate_package_with_supplier
      ),
    },
    relationships: {
      company: {
        data: {
          id: arbocontract.company_id,
          type: "company--company",
        },
      },
      contract_document: {
        data: arbocontract.contract_document
          ? {
              id: arbocontract.contract_document.id,
              type: arbocontract.contract_document.type,
            }
          : null,
      },
    },
  };

  if (arbocontract.id) {
    serialized.id = arbocontract.id;
  }

  return serialized;
}

function valueToBooleanOrUndefined(value: any) {
  if (value === null) {
    return undefined;
  }
  return value === "1";
}

function deserializer(arbocontractData: any, included: any, jsonApi: any) {
  let contract_document_data =
    arbocontractData.relationships.contract_document.data;

  let arbocontract = newArbocontract(
    arbocontractData.relationships.company.data.id
  );

  arbocontract.id = arbocontractData.id;
  arbocontract.company_id = arbocontractData.relationships.company.data.id;
  arbocontract.created = arbocontractData.attributes.created;
  arbocontract.changed = arbocontractData.attributes.changed;
  arbocontract.has_arbocontract =
    arbocontractData.attributes.has_arbocontract !== null
      ? arbocontractData.attributes.has_arbocontract
      : undefined;
  arbocontract.supplier = arbocontractData.attributes.supplier;
  arbocontract.has_employees = valueToBooleanOrUndefined(
    arbocontractData.attributes.has_employees
  );
  arbocontract.expects_to_employ_or_has_temp = valueToBooleanOrUndefined(
    arbocontractData.attributes.expects_to_employ_or_has_temp
  );
  arbocontract.has_absenteeism_insurance = valueToBooleanOrUndefined(
    arbocontractData.attributes.has_absenteeism_insurance
  );
  arbocontract.has_separate_package_with_supplier = valueToBooleanOrUndefined(
    arbocontractData.attributes.has_separate_package_with_supplier
  );

  if (contract_document_data && included) {
    // Contract_document file linked.
    arbocontract.contract_document = jsonApi.deserialize.collection
      .call(jsonApi, included)
      .find(function (included: any) {
        return (
          included.type === contract_document_data.type &&
          included.id === contract_document_data.id
        );
      });
  }

  arbocontract.initialContract_document = arbocontract.contract_document;

  return arbocontract;
}

const jsonApiModel: JsonApiModel = {
  name: "arbocontract--arbocontract",
  constructor: {
    has_arbocontract: undefined,
    supplier: undefined,
    has_employees: undefined,
    expects_to_employ_or_has_temp: undefined,
    has_absenteeism_insurance: undefined,
    has_separate_package_with_supplier: undefined,
    company: {
      jsonApi: "hasOne",
      type: "company--company",
    },
    contract_document: {
      jsonApi: "hasOne",
      type: "file--file",
    },
  },
  options: {
    collectionPath: "arbocontract/arbocontract",
    serializer: serializer,
    deserializer: deserializer,
  },
  includeParams: ["contract_document"],
};

export default jsonApiModel;
