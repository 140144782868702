import {
  createAsyncThunk,
  createSlice,
  EntityState,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import Client from "../../../Client/Client";
import { JsonApiEntity } from "../JsonApiEntity";
import { File as ClientFile } from "../file";
import { Company } from "../company/slice";

export interface Arbocontract extends JsonApiEntity {
  company_id: string;
  has_arbocontract?: string;
  supplier?: string;
  contract_document?: ClientFile;
  initialContract_document?: ClientFile;
  has_employees?: boolean;
  expects_to_employ_or_has_temp?: boolean;
  has_absenteeism_insurance?: boolean;
  has_separate_package_with_supplier?: boolean;
  localContractDocumentFile?: File;
}

export function newArbocontract(company_id: string): Arbocontract {
  return {
    id: "",
    type: "arbocontract--arbocontract",
    created: "",
    changed: "",
    company_id: company_id,
    has_arbocontract: undefined,
    supplier: undefined,
    contract_document: undefined,
    has_employees: undefined,
    has_absenteeism_insurance: undefined,
    has_separate_package_with_supplier: undefined,
    localContractDocumentFile: undefined,
  };
}

export enum FetchArbocontractStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export const arbocontractEntityAdapter = createEntityAdapter<Arbocontract>();

export interface ArbocontractState extends EntityState<Arbocontract> {
  fetchArbocontractStatus: FetchArbocontractStatus;
  error: string | null;
  waitingForClientUpsertCounter: number;
}

export const initialState: ArbocontractState = arbocontractEntityAdapter.getInitialState(
  {
    fetchArbocontractStatus: FetchArbocontractStatus.Idle,
    error: null,
    waitingForClientUpsertCounter: 0,
  }
);

export const fetchArbocontract = createAsyncThunk<
  Array<object>,
  Company,
  {
    extra: {
      client: Client;
    };
  }
>("arbocontract/fetchArbocontract", async function (arg: Company, thunkAPI) {
  const results = await thunkAPI.extra.client.findAllForCompany(
    arg,
    "arbocontract--arbocontract"
  );
  return results.data;
});

export const createArbocontract = createAsyncThunk<
  Arbocontract,
  Arbocontract,
  {
    extra: {
      client: Client;
    };
  }
>(
  "arbocontract/createArbocontract",
  async function (arg: Arbocontract, thunkAPI) {
    let params: any = {},
      createdContractDocumentFile: ClientFile | undefined;

    if (arg.localContractDocumentFile) {
      const file_results = await thunkAPI.extra.client.createFile(
        arg.type,
        "contract_document",
        arg.localContractDocumentFile
      );

      if (file_results.status !== 201) {
        throw Error(
          "Contract_document upload failed with error code " +
            file_results.status
        );
      }

      createdContractDocumentFile = thunkAPI.extra.client.jsonApi.deserialize.resource.call(
        thunkAPI.extra.client.jsonApi,
        file_results.data.data
      );

      if (createdContractDocumentFile) {
        arg.contract_document = createdContractDocumentFile;
      }
    }

    const results = await thunkAPI.extra.client.create(arg, params);

    if (createdContractDocumentFile) {
      // The API doesn't return the linked contract_document after creation.
      results.data.contract_document = createdContractDocumentFile;
    }

    return results.data;
  }
);

export const deleteArbocontract = createAsyncThunk<
  string,
  Arbocontract,
  {
    extra: {
      client: Client;
    };
  }
>(
  "arbocontract/deleteArbocontract",
  async function (arg: Arbocontract, thunkAPI) {
    await thunkAPI.extra.client.delete(arg);

    return arg.id;
  }
);

export const updateArbocontract = createAsyncThunk<
  Arbocontract,
  Arbocontract,
  {
    extra: {
      client: Client;
    };
  }
>(
  "arbocontract/updateArbocontract",
  async function (arg: Arbocontract, thunkAPI) {
    const results = await thunkAPI.extra.client.update(arg);

    if (arg.localContractDocumentFile) {
      const file_results = await thunkAPI.extra.client.updateFile(
        arg,
        "contract_document",
        arg.localContractDocumentFile
      );

      if (file_results.status !== 200) {
        throw Error(
          "Contract_document upload failed with error code " +
            file_results.status
        );
      }

      let createdContractDocumentFile:
        | ClientFile
        | undefined = thunkAPI.extra.client.jsonApi.deserialize.resource.call(
        thunkAPI.extra.client.jsonApi,
        file_results.data.data
      );

      if (createdContractDocumentFile) {
        results.data.contract_document = createdContractDocumentFile;
        arg.contract_document = createdContractDocumentFile;
      }
    } else {
      results.data.contract_document = arg.contract_document;

      if (
        results.data.contract_document === undefined &&
        arg.initialContract_document !== undefined
      ) {
        // Remove current contract_document.
        const file_results = await thunkAPI.extra.client.deleteFile(
          arg.initialContract_document
        );

        if (file_results.status !== 204) {
          throw Error(
            "Contract_document delete failed with error code " +
              file_results.status
          );
        }

        arg.initialContract_document = undefined;
      }
    }

    return results.data;
  }
);

function increaseWaitingForClientUpsertCounter(
  state: ArbocontractState,
  action: any
) {
  state.waitingForClientUpsertCounter++;
}

function decreaseWaitingForClientUpsertCounter(
  state: ArbocontractState,
  action: any
) {
  state.waitingForClientUpsertCounter--;
}

const slice = createSlice({
  name: "arbocontract",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchArbocontract.pending.toString()]: function (
      state: ArbocontractState,
      action: any
    ) {
      state.fetchArbocontractStatus = FetchArbocontractStatus.Loading;
      arbocontractEntityAdapter.removeAll(state);
    },
    [fetchArbocontract.rejected.toString()]: function (
      state: ArbocontractState,
      action: any
    ) {
      state.fetchArbocontractStatus = FetchArbocontractStatus.Failed;
      state.error = action.error.message;
    },
    [fetchArbocontract.fulfilled.toString()]: function (
      state: ArbocontractState,
      action: any
    ) {
      state.fetchArbocontractStatus = FetchArbocontractStatus.Succeeded;
      arbocontractEntityAdapter.upsertMany(state, action.payload);
    },

    [createArbocontract.pending.toString()]: increaseWaitingForClientUpsertCounter,
    [createArbocontract.rejected.toString()]: decreaseWaitingForClientUpsertCounter,
    [createArbocontract.fulfilled.toString()]: function (
      state: ArbocontractState,
      action: any
    ) {
      arbocontractEntityAdapter.addOne(state, action.payload);
      decreaseWaitingForClientUpsertCounter(state, action);
    },

    [deleteArbocontract.fulfilled.toString()]: function (
      state: ArbocontractState,
      action: any
    ) {
      arbocontractEntityAdapter.removeOne(state, action.payload);
    },

    [updateArbocontract.pending.toString()]: increaseWaitingForClientUpsertCounter,
    [updateArbocontract.rejected.toString()]: decreaseWaitingForClientUpsertCounter,
    [updateArbocontract.fulfilled.toString()]: function (
      state: ArbocontractState,
      action: any
    ) {
      arbocontractEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      decreaseWaitingForClientUpsertCounter(state, action);
    },
  },
});

export function selectArbocontractByCompany(
  state: any,
  company: Company
): Arbocontract | undefined {
  let arbocontracts = arbocontractEntityAdapter
    .getSelectors()
    .selectAll(state.arbocontract)
    .filter(function (arbocontract: Arbocontract) {
      return arbocontract.company_id === company.id;
    });

  return arbocontracts[0];
}

export default slice.reducer;
