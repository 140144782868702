import React from "react";
import store from "../Redux/store";
import { LocationsLoadingStatus } from "../Redux/reducer/location/slice";
import AlertDialog from "../Component/Popup/AlertDialog";
import { CompanysLoadingStatus } from "../Redux/reducer/company/slice";
import eventBus from "./eventBus";

let is_terminated: boolean = false;

function terminateWithMessage(message: any) {
  if (is_terminated) {
    return;
  }
  is_terminated = true;
  AlertDialog.show(message, undefined, "Fout", undefined, true);
}

let terminateOnMissingDataLastCompany: any = null;

function dispatchRequestCompanyChange() {
  eventBus.dispatch("RequestCompanyChange");
}

/**
 * This makes sure the app gets terminated when required data is missing.
 */
function terminateOnMissingData() {
  let state = store.getState();

  if (
    (state.company.status === CompanysLoadingStatus.Succeeded ||
      state.company.status === CompanysLoadingStatus.Failed) &&
    state.company.ids.length === 0
  ) {
    terminateWithMessage(
      "Uw gebruikersaccount is niet aan een bedrijf gekoppeld."
    );
  }

  if (
    (state.location.status === LocationsLoadingStatus.Succeeded ||
      state.location.status === LocationsLoadingStatus.Failed) &&
    state.location.ids.length === 0
  ) {
    let message: any =
      "Er zijn geen locaties gekoppeld aan uw gebruikersaccount.";
    if (state.company.ids.length > 1) {
      message = (
        <div>
          <p>{message}</p>
          <p>&nbsp;</p>
          <a
            href="#selecteer-bedrijf"
            className="button secondary"
            onClick={dispatchRequestCompanyChange}
          >
            Selecteer een ander bedrijf
          </a>
        </div>
      );
    }

    terminateWithMessage(message);
  }

  if (
    state.company.status === CompanysLoadingStatus.Succeeded &&
    state.company.currentCompany &&
    state.company.currentCompany.task.length === 0 &&
    (terminateOnMissingDataLastCompany === null ||
      terminateOnMissingDataLastCompany !== state.company.currentCompany)
  ) {
    let message: any = "Er zijn geen taken gekoppeld aan uw bedrijf.";
    if (state.company.ids.length > 1) {
      message = (
        <div>
          <p>{message}</p>
          <p>&nbsp;</p>
          <a
            href="#selecteer-bedrijf"
            className="button secondary"
            onClick={dispatchRequestCompanyChange}
          >
            Selecteer een ander bedrijf
          </a>
        </div>
      );
    }

    terminateWithMessage(message);
    terminateOnMissingDataLastCompany = state.company.currentCompany;
  }
}

store.subscribe(terminateOnMissingData);
