import {
  CompanyProgress,
  LocationProgress,
  Progress,
  UpdateProgressPayload,
} from "../../Redux/reducer/progress/slice";

function pluginProcessDeserializer(
  location_id: string,
  plugin_id: string,
  data: any
) {
  let plugin_progress: Progress = {
    location_id: location_id,
    plugin_id: plugin_id,
    activated: data.activated === true,
    completed: data.completed === true,
    percentage: parseInt(data.percentage, 10),
    group: data.group,
    notification: data.notification,
    lastActivity:
      data.lastActivity !== null ? parseInt(data.lastActivity, 10) : undefined,
  };

  return plugin_progress;
}

function pluginsProcessDeserializer(location_id: string, plugins: any) {
  let plugins_process: any = {};

  Object.keys(plugins).forEach(function (plugin_id: any) {
    plugins_process[plugin_id] = pluginProcessDeserializer(
      location_id,
      plugin_id,
      plugins[plugin_id]
    );
  });

  return plugins_process;
}

export function getUpdateProgressPayloadFromMetaObject(meta: any) {
  let updateProgressPayload: UpdateProgressPayload = {
      company: [],
    },
    companyProgress: CompanyProgress;

  Object.keys(meta.progress).forEach(function (company_id: any) {
    let locations: Array<LocationProgress> = [];
    Object.keys(meta.progress[company_id]["locationProgress"]).forEach(
      function (location_id: any) {
        let data = meta.progress[company_id]["locationProgress"][location_id],
          locationProgress: LocationProgress = {
            location_id: location_id,
            progress: pluginsProcessDeserializer(location_id, data.plugins),
            nextTaskPluginId:
              data.nextTaskPluginId !== null
                ? data.nextTaskPluginId
                : undefined,
          };

        locations.push(locationProgress);
      }
    );

    companyProgress = {
      company_id: company_id,
      location: locations,
    };

    updateProgressPayload.company.push(companyProgress);
  });

  return updateProgressPayload;
}
