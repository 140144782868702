import React, { Component } from "react";
import SidebarLocationSelector from "./SidebarLocationSelector";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { User } from "../../Redux/reducer/user/slice";
import { Company } from "../../Redux/reducer/company/slice";
import { Location } from "../../Redux/reducer/location/slice";

type SidebarLeftProps = {
  user?: User;
  currentCompany?: Company;
  currentLocation?: Location;
};

function mapState(state: any) {
  return {
    user: state.user,
    currentCompany: state.company.currentCompany,
    currentLocation: state.location.currentLocation,
  };
}

class SidebarLeft extends Component<SidebarLeftProps, {}> {
  render() {
    if (
      this.props.user === null ||
      !this.props.currentCompany ||
      !this.props.currentLocation
    ) {
      return <aside className="sidebar-left"></aside>;
    }

    return (
      <aside className="sidebar-left">
        <SidebarLocationSelector />
        <div className="sidebar-menu">
          {this.props.currentCompany.subject.map(function (subject, index) {
            let className = "sidebar-link Din-medium subject-" + subject.id,
              url = "/" + subject.path;
            return (
              <NavLink key={index} className={className} to={url}>
                <span>{subject.title}</span>
              </NavLink>
            );
          })}
        </div>
      </aside>
    );
  }
}

export default connect(mapState, {})(SidebarLeft);
