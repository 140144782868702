class EventBus {
  private listeners: any;

  constructor() {
    this.listeners = [];
  }

  addEventListener(event: string, callback: any) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(callback);

    document.addEventListener(event, function (e: any) {
      callback(e.detail);
    });
  }

  removeEventListener(event: string, callback: any) {
    let index = this.listeners[event]
      ? this.listeners[event].indexOf(callback)
      : -1;

    if (index === -1) {
      return;
    }

    this.listeners[event] = this.listeners[event].splice(index, 1);
  }

  dispatch(event: string, data?: any) {
    if (!this.listeners[event]) {
      return;
    }

    this.listeners[event].forEach(function (callback: any) {
      callback(data);
    });
  }
}

const eventBus = new EventBus();

export default eventBus;
