import {
  createAsyncThunk,
  createSlice,
  EntityState,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import Client from "../../../Client/Client";
import { JsonApiEntity } from "../JsonApiEntity";
import { Subject } from "../subject/subject";
import { Task } from "../task/task";
import eventBus from "../../../App/eventBus";

export interface Company extends JsonApiEntity {
  name?: string;
  task: Array<Task>;
  subject: Array<Subject>;
  employeesFixed?: number;
  employeesFlex?: number;
  certified?: boolean;
  certifications?: string;
  visitorAddressStreet?: undefined;
  visitorAddressZipcode?: undefined;
  visitorAddressCity?: undefined;
  contact?: undefined;
  email?: undefined;
  telephone?: undefined;
  fax?: undefined;
  description?: undefined;
  mijnStigasApiEditLink?: undefined;
}

export function newCompany(): Company {
  return {
    id: "",
    type: "company--company",
    created: "",
    changed: "",
    name: undefined,
    task: [],
    subject: [],
    employeesFixed: undefined,
    employeesFlex: undefined,
    certified: undefined,
    certifications: undefined,
    visitorAddressStreet: undefined,
    visitorAddressZipcode: undefined,
    visitorAddressCity: undefined,
    contact: undefined,
    email: undefined,
    telephone: undefined,
    fax: undefined,
    description: undefined,
    mijnStigasApiEditLink: undefined,
  };
}

export enum CompanysLoadingStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export const companyEntityAdapter = createEntityAdapter<Company>();

export interface CompanyState extends EntityState<Company> {
  status: CompanysLoadingStatus;
  error: string | null;
  currentCompany?: Company;
}

export const initialState: CompanyState = companyEntityAdapter.getInitialState({
  status: CompanysLoadingStatus.Idle,
  error: null,
  currentCompany: undefined,
});

export const fetchCompanies = createAsyncThunk<
  Array<object>,
  void,
  {
    extra: {
      client: Client;
    };
  }
>("company/fetchCompanies", async function (arg: void, thunkAPI) {
  const results = await thunkAPI.extra.client.findAll("company--company");
  return results.data;
});

export class SetCurrentCompanyPayload {
  company?: Company;

  constructor(company?: Company) {
    this.company = company;
  }
}

function dispatchCompanyChanged(company?: Company) {
  eventBus.dispatch("CompanyChanged", company);
}

const slice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    setCurrentCompany(state, action: PayloadAction<SetCurrentCompanyPayload>) {
      state.currentCompany = action.payload.company;
      dispatchCompanyChanged(state.currentCompany);
    },
  },
  extraReducers: {
    [fetchCompanies.pending.toString()]: function (state, action) {
      state.status = CompanysLoadingStatus.Loading;
      companyEntityAdapter.removeAll(state);
      state.currentCompany = undefined;
    },
    [fetchCompanies.fulfilled.toString()]: function (state, action) {
      state.status = CompanysLoadingStatus.Succeeded;
      companyEntityAdapter.setAll(state, action.payload);

      // Set the first company as current if there is only one company.
      const selectors = companyEntityAdapter.getSelectors(),
        ids = selectors.selectIds(state);

      if (ids.length === 1) {
        state.currentCompany = selectors.selectById(state, ids[0]) || undefined;
      } else {
        state.currentCompany = undefined;
      }
      dispatchCompanyChanged(state.currentCompany);
    },
    [fetchCompanies.rejected.toString()]: function (state, action) {
      companyEntityAdapter.removeAll(state);
      state.status = CompanysLoadingStatus.Failed;
      state.error = action.error.message;
      state.currentCompany = undefined;
      dispatchCompanyChanged(state.currentCompany);
    },
  },
});

export const { setCurrentCompany } = slice.actions;
export default slice.reducer;

export function companyHasTask(company: Company, task_id: string): boolean {
  return (
    company.task.find(function (companyTask) {
      return companyTask.id === task_id;
    }) !== undefined
  );
}
