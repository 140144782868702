/**
 * Keycloak manager Singleton.
 */
import AlertDialog from "../Component/Popup/AlertDialog";
import Keycloak, {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakInstance,
  KeycloakLoginOptions,
} from "keycloak-js";
import store from "../Redux/store";
import {
  CookiebarState,
  login,
  LoginPayload,
  updateAccessToken,
  UpdateAccessTokenPayload,
  User,
} from "../Redux/reducer/user/slice";
import React from "react";

export default class Manager {
  private REFRESH_TOKEN_TIMEOUT: number = 60000;
  private keycloak: KeycloakInstance;

  private constructor() {
    const config: KeycloakConfig = {
      url: process.env.REACT_APP_KEYCLOAK_URL || "",
      realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID || "",
    };

    const initOptions: KeycloakInitOptions = {
      pkceMethod: "S256",
      enableLogging: window.location.hostname === "localhost",
      checkLoginIframe: false,
    };

    this.keycloak = Keycloak(config);

    this.keycloak
      .init(initOptions)
      .then(this.keycloakInitSucceeded.bind(this))
      .catch(this.keycloakInitFailed.bind(this));
  }

  private keycloakInitSucceeded(authenticated: boolean) {
    if (!authenticated) {
      // Not logged in.
      return;
    }

    // Logged in. Get user data.
    this.keycloak
      .loadUserInfo()
      .then(this.keycloakLoadUserInfoSuccess.bind(this))
      .catch(this.keycloakLoadUserInfoFailed.bind(this));

    setTimeout(
      this.refreshAccessTokenIfNeeded.bind(this),
      this.REFRESH_TOKEN_TIMEOUT
    );
  }

  private keycloakLoadUserInfoSuccess(data: any) {
    // Perform login.

    let given_name = data.given_name,
      name = data.name;

    if (!given_name || given_name.length === 0) {
      given_name = data.preferred_username;
    }

    if (!name || name.length === 0) {
      name = data.preferred_username;
    }

    const user: User = {
      iamId: data.sub,
      email: data.email,
      name: name,
      givenName: given_name,
      familyName: data.family_name,
      permissions: [],
      cookiebarState: CookiebarState.NotLoaded,
    };
    const loginPayload = new LoginPayload(user, this.keycloak.token);

    store.dispatch(login(loginPayload));
  }

  private keycloakLoadUserInfoFailed() {
    console.debug("keycloakLoadUserInfoFailed");
    AlertDialog.show(
      "Probeer de pagina te herladen of neem contact op het Stigas indien het probleem zich blijft voordoen.",
      undefined,
      "IAM gebruikersgegevens",
      undefined,
      true
    );
  }

  private keycloakInitFailed() {
    console.debug("keycloakInitFailed");
    AlertDialog.show(
      "Probeer de pagina te herladen of neem contact op het Stigas indien het probleem zich blijft voordoen.",
      undefined,
      "IAM verbindingsfout",
      undefined,
      true
    );
  }

  private refreshAccessTokenIfNeeded() {
    this.keycloak
      .updateToken(30)
      .then(this.refreshAccessTokenIfNeededSucceeded.bind(this))
      .catch(this.refreshAccessTokenIfNeededFailed.bind(this));
  }

  private refreshAccessTokenIfNeededSucceeded(refreshed: boolean) {
    if (refreshed) {
      // Update access token in redux.
      const updateAccessTokenPayload = new UpdateAccessTokenPayload(
        this.keycloak.token
      );
      store.dispatch(updateAccessToken(updateAccessTokenPayload));
    }
    // Schedule new refresh.
    setTimeout(
      this.refreshAccessTokenIfNeeded.bind(this),
      this.REFRESH_TOKEN_TIMEOUT
    );
  }

  private refreshAccessTokenIfNeededFailed() {
    console.log("refreshAccessTokenIfNeededFailed");

    function reload(e: React.MouseEvent) {
      e.preventDefault();
      window.location.reload();
    }

    AlertDialog.show(
      <p>
        Je bent niet meer ingelogd. Klik{" "}
        <a href="/" onClick={reload}>
          hier
        </a>{" "}
        om opnieuw in te loggen op Mijn Stigas.
      </p>,
      undefined,
      "Sessie verlopen",
      undefined,
      true
    );
  }

  login(redirectToPathAfterLogin?: string) {
    let redirectUri = window.location.origin + "/inloggen/voltooid",
      search = window.location.search;

    if (redirectToPathAfterLogin) {
      if (search !== "") {
        search += "&";
      }
      search +=
        "redirectToPathAfterLogin=" +
        encodeURIComponent(redirectToPathAfterLogin);
    }

    if (search !== "") {
      redirectUri += "?" + search;
    }

    let loginOptions: KeycloakLoginOptions = {
      redirectUri: redirectUri,
    };

    this.keycloak.login(loginOptions);
  }

  logout() {
    this.keycloak.logout({
      redirectUri: window.location.origin + "/",
    });
  }

  getAccessToken() {
    return this.keycloak.token;
  }

  private static instance: Manager;

  public static getInstance(): Manager {
    if (!Manager.instance) {
      Manager.instance = new Manager();
    }

    return Manager.instance;
  }
}
