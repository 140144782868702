export function getQueryParameter(parameter: string) {
  var query = window.location.search.replace(/\?/g, ""),
    vars = query.split("&"),
    i,
    pair;

  for (i = 0; i < vars.length; i++) {
    pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === parameter) {
      return decodeURIComponent(pair[1]);
    }
  }
  return false;
}
