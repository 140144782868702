import React, { Component } from "react";
import "./Progressbar.scss";

type ProgressbarProps = {
  size?: string;
  progress?: number;
};

export default class Progressbar extends Component<ProgressbarProps, {}> {
  render() {
    const progress_style = {
      width: (this.props.progress ? this.props.progress : 0) + "%",
    };

    return (
      <div className={"progress-container " + this.props.size || ""}>
        <div className="progress">
          <div className="progress-bar" style={progress_style}></div>
        </div>
        <span className="progress-label">
          {this.props.progress ? this.props.progress : 0}%
        </span>
      </div>
    );
  }
}
