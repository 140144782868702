import React, { Component } from "react";
import DefaultPageLayout from "../DefaultPageLayout/DefaultPageLayout";
import ConfirmDialog from "../../Component/Popup/ConfirmDialog";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Manager from "../../Keycloak/Manager";

class Logout extends Component<RouteComponentProps, {}> {
  private logoutConfirm() {
    let manager = Manager.getInstance();
    manager.logout();
  }

  private logoutCancel() {
    this.props.history.push("/");
  }

  componentDidMount() {
    ConfirmDialog.show(
      "Weet u zeker dat u wilt uitloggen?",
      this.logoutConfirm.bind(this),
      this.logoutCancel.bind(this),
      "Uitloggen"
    );
  }

  render() {
    return <DefaultPageLayout title="Uitloggen"></DefaultPageLayout>;
  }
}

export default withRouter(Logout);
