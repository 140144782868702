import { JsonApiEntity } from "./JsonApiEntity";

export interface File extends JsonApiEntity {
  filename: string;
  filemime: string;
  filesize: number;
  url: string;
}

export function newFile(): File {
  return {
    id: "",
    type: "file--file",
    created: "",
    changed: "",
    filename: "",
    filemime: "",
    filesize: 0,
    url: "",
  };
}
