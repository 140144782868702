import KeycloakClient from "./KeycloakClient";
import Client from "./Client";

let default_client_instance: Client;

export function getDefaultClient(): Client {
  if (default_client_instance instanceof Client) {
    return default_client_instance;
  }

  default_client_instance = new KeycloakClient(
    process.env.REACT_APP_BACKEND_BASE_URL || ""
  );

  return default_client_instance;
}
