import { Quote } from "../Component/Quote/Quote";

const quotes: Array<Quote> = [
  {
    text: "De RIE van Stigas is speciaal ingericht voor én door ondernemers en medewerkers uit de sector.",
  },
  {
    text: "Met de verzuimbegeleiding van Stigas heb ik een vast contactpersoon en een vaste bedrijfsarts van Stigas.",
    author: "Myra Waegemakers",
    company: "Van der Tol Groep",
  },
  {
    text: "Veilig werken straat professionaliteit uit en geeft ons een serieuze status in de branche.",
    author: "Wil Huijbregts",
    company: "W. Huijbregts Zundert",
  },
  {
    text: "Veilig werken is tegenwoordig breder dan alleen werken met veilige machines en technieken.",
  },
  {
    text: "Ik krijg waardering van mijn collega’s als we veilig werken.",
    author: "Peter Verheijen",
    company: "Van der Avoird Trayplant",
  },
  {
    text: "Medewerkers zijn het belangrijkste kapitaal van iedere organisatie.",
  },
  {
    text: "Stigas biedt alles om medewerkers en bedrijven gezond te houden.",
  },
  {
    text: "Stigas biedt alles om medewerkers en bedrijven gezond te houden.",
  },
  {
    text: "Verzuim voorkomen? Regel het met Stigas.",
  },
  {
    text: "Vitale medewerkers? Regel het met Stigas.",
  },
  {
    text: "Veilige werkomgeving? Regel het met Stigas.",
  },
  {
    text: "Personeel staat bij ons op de eerste plaats.",
    author: "Peter Verheijen",
    company: "Van der Avoird Trayplant",
  },
  {
    text: "Als je de basis van de RIE op orde hebt, heb je maar een paar uur per jaar nodig om hem goed bij te houden.",
    author: "Petra van Dongen",
    company: "Loonbedrijf Van Dongen",
  },
  {
    text: "Mijn Stigas is een verlengstuk voor ons bedrijf om gezond te werken.",
  },
];

export function getRandomQuote(): Quote | undefined {
  let random_quote_index = Math.floor(Math.random() * quotes.length);

  return quotes[random_quote_index];
}
