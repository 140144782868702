import { selectSubjectById } from "../subject/subject";
import { Company } from "../company/slice";

export interface Task {
  id: string;
  subject_id: string;
  progress_plugin_id: string;
  title: string;
  path: string;
  weight: number;
  url: string;
  usp: Array<string>;
  show_progress: boolean;
}

export function selectTaskById(id: string, company: Company) {
  for (let i in company.task) {
    if (company.task[i].id === id) {
      return company.task[i];
    }
  }
  return undefined;
}

export function getTaskUrl(task: Task, company: Company) {
  let subject = selectSubjectById(task.subject_id, company);

  if (!subject) {
    return undefined;
  }

  return subject.url + "/" + task.path;
}

export function selectTasksBySubject(
  subject_id: string,
  company: Company
): any {
  let tasks: any = {};

  company.task.forEach(function (task) {
    if (task.subject_id === subject_id) {
      tasks[task.id] = task;
    }
  });

  return tasks;
}

export function selectTaskByProgressPluginId(
  progress_plugin_id: string,
  company: Company
): Task | undefined {
  return company.task.find(function (task) {
    return task.progress_plugin_id === progress_plugin_id;
  });
}
