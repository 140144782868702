import React, { Component } from "react";
import Popup from "./Popup";

type CloseButtonProps = {
  popup: Popup;
};

export default class CloseButton extends Component<CloseButtonProps, {}> {
  handleClick(e: React.MouseEvent) {
    this.props.popup.close();
  }

  render() {
    return (
      <div className="popup-close-button" onClick={this.handleClick.bind(this)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.223"
          height="16.111"
          viewBox="0 0 16.223 16.111"
        >
          <g transform="translate(-30.003 0.001)">
            <path
              className="a"
              d="M7.339,8.057.157,15.238a.511.511,0,1,0,.722.722L8.115,8.726l7.235,7.235a.511.511,0,1,0,.722-.722L8.891,8.057,16.076.871a.511.511,0,1,0-.722-.722l-7.24,7.24L.874.149A.511.511,0,1,0,.152.871Z"
              transform="translate(30)"
            />
          </g>
        </svg>
      </div>
    );
  }
}
