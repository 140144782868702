import React, { Component } from "react";
import Header from "./Header";
import SidebarLeft from "./SidebarLeft";
import "./DefaultPageLayout.scss";

type DefaultPageLayoutProps = {
  title: string;
  preventUpdatingDocumentTitle?: boolean;
};

export default class DefaultPageLayout extends Component<
  DefaultPageLayoutProps,
  {}
> {
  constructor(props: any) {
    super(props);
    this.didScroll = this.didScroll.bind(this);
  }

  componentDidMount() {
    this.didScroll();
    window.addEventListener("scroll", this.didScroll);
    if (!this.props.preventUpdatingDocumentTitle) {
      document.title = "Mijn Stigas | " + this.props.title;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.didScroll);
  }

  didScroll() {
    let scroll = window.pageYOffset,
      headerContainer = document.querySelector(
        ".default-page-layout"
      ) as HTMLElement;
    if (scroll > 100) {
      headerContainer.classList.add("scrolled");
    } else {
      headerContainer.classList.remove("scrolled");
    }
  }

  render() {
    return (
      <div className="default-page-layout">
        <Header />
        <div className="main">
          <SidebarLeft />
          <div className="container main-container">
            <div className="row">
              <div className="col-12">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
