import { createSlice } from "@reduxjs/toolkit";
import { File as ClientFile } from "../file";
import { JsonApiEntity } from "../JsonApiEntity";

export interface Advisor extends JsonApiEntity {
  name?: string;
  email?: string;
  telephone?: string;
  role?: string;
  photo?: ClientFile;
}

export function newAdvisor(): Advisor {
  return {
    id: "",
    type: "advisor--advisor",
    created: "",
    changed: "",
    name: undefined,
    email: undefined,
    telephone: undefined,
    role: undefined,
    photo: undefined,
  };
}

interface AdvisorState {
  advisor: Array<Advisor>;
}

export const initialState: AdvisorState = {
  advisor: [],
};

const slice = createSlice({
  name: "advisor",
  initialState: initialState,
  reducers: {},
});

export default slice.reducer;
