import React, { Component } from "react";
import { Subject } from "../../Redux/reducer/subject/subject";
import Progressbar from "../Progressbar/Progressbar";
import { Link } from "react-router-dom";
import { selectTasksBySubject, Task } from "../../Redux/reducer/task/task";
import { Company } from "../../Redux/reducer/company/slice";
import { LocationProgress } from "../../Redux/reducer/progress/slice";

interface SubjectProps {
  isOpen: boolean;
  subject: Subject;
  company: Company;
  progress: LocationProgress;
}

export default class SubjectProgress extends Component<SubjectProps, any> {
  componentDidMount() {
    let openSubject = document.querySelector(".subject.open") as HTMLElement,
      subjectOuter = openSubject.querySelector(".subject-tasks") as HTMLElement,
      subjectInner = openSubject.querySelector(
        ".subject-tasks-inner"
      ) as HTMLElement;
    subjectOuter.style.height = subjectInner.clientHeight + 30 + "px";
  }
  render() {
    let self = this,
      tasks: Array<Task> = Object.values(
        selectTasksBySubject(this.props.subject.id, this.props.company)
      ),
      className = "subject",
      progress =
        self.props.progress.progress[self.props.subject.progress_plugin_id];

    if (this.props.isOpen) {
      className += " open";
    }

    return (
      <div className={className}>
        <div className="subject-top" data-subject-id={this.props.subject.id}>
          <h3 className="subject-title">
            <Link to={this.props.subject.url}>{this.props.subject.title}</Link>
          </h3>
          <Progressbar
            size="extra-small"
            progress={progress ? progress.percentage : 0}
          />
        </div>
        <div className="subject-tasks">
          <div className="subject-tasks-inner">
            {tasks.slice(0, 2).map(function (task, index) {
              let progress =
                  self.props.progress.progress[task.progress_plugin_id],
                statusClassName = "start",
                statusMessage = "Aan de slag";

              if (!progress || !progress.activated) {
                return null;
              }

              if (progress) {
                if (progress.completed) {
                  statusClassName = "completed";
                  statusMessage = "Afgerond";
                } else if (progress.activated && progress.percentage > 0) {
                  statusClassName = "continue";
                  statusMessage = "Ga verder";
                }
              }

              return (
                <Link
                  to={task.url}
                  className="task"
                  key={index}
                  data-task-id={task.id}
                >
                  {task.title}
                  <span className={statusClassName}>{statusMessage}</span>
                </Link>
              );
            })}
            <Link to={this.props.subject.url} className="more-tasks">
              Bekijk meer
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
