import { Route, RouteProps } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../Page/User/Login";
import { User } from "../Redux/reducer/user/slice";
import TextPageLayout from "../Page/TextPageLayout/TextPageLayout";

interface PrivateRouteProps extends RouteProps {
  user?: User;
  requiredUserPermissions?: Array<string>;
}

function mapState(state: any) {
  return {
    user: state.user.user,
  };
}

class PrivateRoute extends Component<PrivateRouteProps, {}> {
  hasRequiredPermissions() {
    if (!this.props.user) {
      return false;
    }

    if (this.props.requiredUserPermissions) {
      let i = this.props.requiredUserPermissions.length;
      while (i--) {
        if (
          this.props.user.permissions.indexOf(
            this.props.requiredUserPermissions[i]
          ) === -1
        ) {
          return false;
        }
      }
    }

    return true;
  }

  render() {
    if (!this.props.user) {
      // Not logged in.
      return (
        <Login
          redirectToPathAfterLogin={
            window.location.pathname + window.location.search
          }
        />
      );
    }

    if (!this.hasRequiredPermissions()) {
      return (
        <TextPageLayout title="Geen toegang">
          <p>Geen toegang tot deze pagina.</p>
        </TextPageLayout>
      );
    }

    // Logged in.
    return (
      <Route path={this.props.path} exact={this.props.exact}>
        {this.props.children}
      </Route>
    );
  }
}

export default connect(mapState, {})(PrivateRoute);
