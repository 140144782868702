import { newPremed, Premed } from "./slice";
import moment from "moment";
import { JsonApiModel } from "../JsonApiEntity";

function serializer(premed: Premed, included: any, jsonApi: any) {
  let serialized: any = {
    type: premed.type,
    attributes: {
      name: premed.name,
      since: premed.since
        ? moment(premed.since).toISOString().split("T")[0]
        : null,
      elearning_date: premed.elearning_date
        ? moment(premed.elearning_date).toISOString().split("T")[0]
        : null,
    },
    relationships: {
      location: {
        data: {
          id: premed.location_id,
          type: "location--location",
        },
      },
    },
  };

  if (premed.id) {
    serialized.id = premed.id;
  }

  return serialized;
}

function deserializer(premedData: any, included: any, jsonApi: any) {
  let premed = newPremed(premedData.relationships.location.data.id);

  let elearning_date = premedData.attributes.elearning_date
    ? premedData.attributes.elearning_date
    : undefined;

  premed.id = premedData.id;
  premed.created = premedData.attributes.created;
  premed.changed = premedData.attributes.changed;
  premed.name = premedData.attributes.name;
  premed.since = premedData.attributes.since;
  premed.elearning_date = elearning_date;

  return premed;
}

const jsonApiModel: JsonApiModel = {
  name: "premed--premed",
  constructor: {
    name: "",
    since: "",
    elearning_date: "",
    location: {
      jsonApi: "hasOne",
      type: "location--location",
    },
  },
  options: {
    collectionPath: "premed/premed",
    serializer: serializer,
    deserializer: deserializer,
  },
};

export default jsonApiModel;
