import {
  createAsyncThunk,
  createSlice,
  EntityState,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import Client from "../../../Client/Client";
import { JsonApiEntity } from "../JsonApiEntity";
import { Location } from "../location/slice";
import { File as ClientFile } from "../file";

export interface Safeworkinstruction extends JsonApiEntity {
  instructor_name?: string;
  subject?: string;
  attendee: Array<string>;
  instruction_date?: string;
  location_id: string;
  instruction?: ClientFile;
  initialInstruction?: ClientFile;
  localInstructionFile?: File;
}

export function newSafeworkinstruction(
  location_id: string
): Safeworkinstruction {
  return {
    id: "",
    type: "safeworkinstruction--safeworkinstruction",
    created: "",
    changed: "",
    instructor_name: undefined,
    subject: undefined,
    attendee: [],
    instruction_date: undefined,
    location_id: location_id,
    instruction: undefined,
    localInstructionFile: undefined,
  };
}

export enum FetchSafeworkinstructionsStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export const safeworkinstructionEntityAdapter = createEntityAdapter<Safeworkinstruction>();

export interface SafeworkinstructionState
  extends EntityState<Safeworkinstruction> {
  fetchSafeworkinstructionsStatus: FetchSafeworkinstructionsStatus;
  error: string | null;
  waitingForClientUpsertCounter: number;
}

export const initialState: SafeworkinstructionState = safeworkinstructionEntityAdapter.getInitialState(
  {
    fetchSafeworkinstructionsStatus: FetchSafeworkinstructionsStatus.Idle,
    error: null,
    waitingForClientUpsertCounter: 0,
  }
);

export const fetchSafeworkinstructions = createAsyncThunk<
  Array<object>,
  Location,
  {
    extra: {
      client: Client;
    };
  }
>(
  "safeworkinstruction/fetchSafeworkinstructions",
  async function (arg: Location, thunkAPI) {
    const results = await thunkAPI.extra.client.findAllForLocation(
      arg,
      "safeworkinstruction--safeworkinstruction"
    );
    return results.data;
  }
);

export const createSafeworkinstruction = createAsyncThunk<
  Safeworkinstruction,
  Safeworkinstruction,
  {
    extra: {
      client: Client;
    };
  }
>(
  "safeworkinstruction/createSafeworkinstruction",
  async function (arg: Safeworkinstruction, thunkAPI) {
    let params: any = {},
      createdInstructionFile: ClientFile | undefined;

    if (arg.localInstructionFile) {
      const file_results = await thunkAPI.extra.client.createFile(
        arg.type,
        "instruction",
        arg.localInstructionFile
      );

      if (file_results.status !== 201) {
        throw Error(
          "Instruction upload failed with error code " + file_results.status
        );
      }

      createdInstructionFile = thunkAPI.extra.client.jsonApi.deserialize.resource.call(
        thunkAPI.extra.client.jsonApi,
        file_results.data.data
      );

      if (createdInstructionFile) {
        arg.instruction = createdInstructionFile;
      }
    }

    const results = await thunkAPI.extra.client.create(arg, params);

    if (createdInstructionFile) {
      // The API doesn't return the linked instruction after creation.
      results.data.instruction = createdInstructionFile;
    }

    return results.data;
  }
);

export const deleteSafeworkinstruction = createAsyncThunk<
  string,
  Safeworkinstruction,
  {
    extra: {
      client: Client;
    };
  }
>(
  "safeworkinstruction/deleteSafeworkinstruction",
  async function (arg: Safeworkinstruction, thunkAPI) {
    await thunkAPI.extra.client.delete(arg);

    return arg.id;
  }
);

export const updateSafeworkinstruction = createAsyncThunk<
  Safeworkinstruction,
  Safeworkinstruction,
  {
    extra: {
      client: Client;
    };
  }
>(
  "safeworkinstruction/updateSafeworkinstruction",
  async function (arg: Safeworkinstruction, thunkAPI) {
    const results = await thunkAPI.extra.client.update(arg);

    if (arg.localInstructionFile) {
      const file_results = await thunkAPI.extra.client.updateFile(
        arg,
        "instruction",
        arg.localInstructionFile
      );

      if (file_results.status !== 200) {
        throw Error(
          "Instruction upload failed with error code " + file_results.status
        );
      }

      let createdInstructionFile:
        | ClientFile
        | undefined = thunkAPI.extra.client.jsonApi.deserialize.resource.call(
        thunkAPI.extra.client.jsonApi,
        file_results.data.data
      );

      if (createdInstructionFile) {
        results.data.instruction = createdInstructionFile;
        arg.instruction = createdInstructionFile;
      }
    } else {
      results.data.instruction = arg.instruction;

      if (
        results.data.instruction === undefined &&
        arg.initialInstruction !== undefined
      ) {
        // Remove current instruction.
        const file_results = await thunkAPI.extra.client.deleteFile(
          arg.initialInstruction
        );

        if (file_results.status !== 204) {
          throw Error(
            "Instruction delete failed with error code " + file_results.status
          );
        }

        arg.initialInstruction = undefined;
      }
    }

    return results.data;
  }
);

function increaseWaitingForClientUpsertCounter(
  state: SafeworkinstructionState,
  action: any
) {
  state.waitingForClientUpsertCounter++;
}

function decreaseWaitingForClientUpsertCounter(
  state: SafeworkinstructionState,
  action: any
) {
  state.waitingForClientUpsertCounter--;
}

const slice = createSlice({
  name: "safeworkinstruction",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchSafeworkinstructions.pending.toString()]: function (
      state: SafeworkinstructionState,
      action: any
    ) {
      state.fetchSafeworkinstructionsStatus =
        FetchSafeworkinstructionsStatus.Loading;
      safeworkinstructionEntityAdapter.removeAll(state);
    },
    [fetchSafeworkinstructions.rejected.toString()]: function (
      state: SafeworkinstructionState,
      action: any
    ) {
      state.fetchSafeworkinstructionsStatus =
        FetchSafeworkinstructionsStatus.Failed;
      state.error = action.error.message;
    },
    [fetchSafeworkinstructions.fulfilled.toString()]: function (
      state: SafeworkinstructionState,
      action: any
    ) {
      state.fetchSafeworkinstructionsStatus =
        FetchSafeworkinstructionsStatus.Succeeded;
      safeworkinstructionEntityAdapter.upsertMany(state, action.payload);
    },

    [createSafeworkinstruction.pending.toString()]: increaseWaitingForClientUpsertCounter,
    [createSafeworkinstruction.rejected.toString()]: decreaseWaitingForClientUpsertCounter,
    [createSafeworkinstruction.fulfilled.toString()]: function (
      state: SafeworkinstructionState,
      action: any
    ) {
      safeworkinstructionEntityAdapter.addOne(state, action.payload);
      decreaseWaitingForClientUpsertCounter(state, action);
    },

    [deleteSafeworkinstruction.fulfilled.toString()]: function (
      state: SafeworkinstructionState,
      action: any
    ) {
      safeworkinstructionEntityAdapter.removeOne(state, action.payload);
    },

    [updateSafeworkinstruction.pending.toString()]: increaseWaitingForClientUpsertCounter,
    [updateSafeworkinstruction.rejected.toString()]: decreaseWaitingForClientUpsertCounter,
    [updateSafeworkinstruction.fulfilled.toString()]: function (
      state: SafeworkinstructionState,
      action: any
    ) {
      safeworkinstructionEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      decreaseWaitingForClientUpsertCounter(state, action);
    },
  },
});

export function selectAllSafeworkinstructionsByLocation(
  state: any,
  location: Location
): Array<Safeworkinstruction> {
  return safeworkinstructionEntityAdapter
    .getSelectors()
    .selectAll(state.safeworkinstruction)
    .filter(function (safeworkinstruction: Safeworkinstruction) {
      return safeworkinstruction.location_id === location.id;
    });
}

export default slice.reducer;
