import React, { Component, MutableRefObject } from "react";
import "./DashboardProgress.scss";
import { connect } from "react-redux";
import {
  LocationProgress,
  selectLocationProgressByLocation,
} from "../../Redux/reducer/progress/slice";
import { Company } from "../../Redux/reducer/company/slice";
import SubjectProgress from "./SubjectProgress";
import { Subject } from "../../Redux/reducer/subject/subject";

interface DasboardProgressProps {
  location?: Location;
  company?: Company;
  progress?: LocationProgress;
}

function mapState(state: any) {
  let location = state.location.currentLocation,
    company = state.company.currentCompany;

  return {
    location: location,
    company: company,
    progress:
      location && company
        ? selectLocationProgressByLocation(state, company, location)
        : undefined,
  };
}

class DashboardProgress extends Component<DasboardProgressProps, any> {
  protected ref: MutableRefObject<HTMLDivElement | null>;
  protected subjects: Array<HTMLElement>;

  constructor(props: any, context: any) {
    super(props, context);

    this.ref = React.createRef();
    this.subjects = [];

    this.subjectBtnOnClick = this.subjectBtnOnClick.bind(this);
  }

  subjectBtnOnClick(e: any) {
    let current_subject = e.currentTarget as HTMLElement;

    while (current_subject && !current_subject.classList.contains("subject")) {
      if (!current_subject.parentElement) {
        return;
      }

      current_subject = current_subject.parentElement;
    }
    e.preventDefault();

    if (!current_subject) {
      return;
    }

    let subjectOuter = current_subject.querySelector(
        ".subject-tasks"
      ) as HTMLElement,
      subjectInner = current_subject.querySelector(
        ".subject-tasks-inner"
      ) as HTMLElement;

    this.subjects.forEach(function (subject) {
      let subjectOuter = subject.querySelector(".subject-tasks") as HTMLElement;
      subject.classList.remove("open");
      subjectOuter.style.height = "0";
    });

    if (subjectOuter.clientHeight === 0) {
      current_subject.classList.add("open");
      subjectOuter.style.height = subjectInner.clientHeight + 30 + "px";
    } else {
      current_subject.classList.remove("open");
      subjectOuter.style.height = "0";
    }
  }

  protected setupCollapsibleSubject() {
    if (!this.ref.current) {
      return;
    }
    this.subjects = Array.prototype.slice.call(
      this.ref.current.querySelectorAll(".subject")
    );

    let self = this;

    this.subjects.forEach(function (subject) {
      if (subject.classList.contains("collapsible-set-up")) {
        return;
      }
      subject.classList.add("collapsible-set-up");

      let subjectBtn = subject.querySelector(".subject-title a");

      if (subjectBtn) {
        subjectBtn.addEventListener("click", self.subjectBtnOnClick);
      }
    });
  }

  componentDidMount() {
    this.setupCollapsibleSubject();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    this.setupCollapsibleSubject();
  }

  getActivatedSubjects(): Array<Subject> {
    let self = this;

    if (!this.props.company || !self.props.progress) {
      return [];
    }

    return this.props.company.subject.filter(function (subject) {
      let progress = self.props.progress?.progress[subject.progress_plugin_id];
      return progress && progress.activated;
    });
  }

  render() {
    let company: Company,
      progress: LocationProgress,
      activatedSubjects = this.getActivatedSubjects();

    if (!this.props.location || !this.props.company || !this.props.progress) {
      return (
        <div
          ref={this.ref}
          className="dashboard-progress no-subjects-activated"
        >
          <div className="wait-message">
            <div className="wait-animation"></div>
          </div>
        </div>
      );
    }

    if (activatedSubjects.length === 0) {
      return (
        <div
          ref={this.ref}
          className="dashboard-progress no-subjects-activated"
        >
          <p>
            Op dit moment is er nog geen taak geactiveerd. Klik op een taak om
            aan de slag te gaan.
          </p>
        </div>
      );
    }

    company = this.props.company;
    progress = this.props.progress;

    return (
      <div ref={this.ref} className="dashboard-progress">
        {activatedSubjects.map(function (subject, index) {
          return (
            <SubjectProgress
              subject={subject}
              company={company}
              progress={progress}
              isOpen={index === 0}
              key={index}
            />
          );
        })}
      </div>
    );
  }
}

export default connect(mapState, {})(DashboardProgress);
