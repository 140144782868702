import { JsonApiModel } from "../JsonApiEntity";
import moment from "moment";
import { Safeworkinstruction, newSafeworkinstruction } from "./slice";

function serializer(safeworkinstruction: Safeworkinstruction) {
  let serialized: any = {
    type: safeworkinstruction.type,
    attributes: {
      instructor_name: safeworkinstruction.instructor_name,
      subject: safeworkinstruction.subject,
      attendee: safeworkinstruction.attendee,
      instruction_date: safeworkinstruction.instruction_date
        ? moment(safeworkinstruction.instruction_date)
            .toISOString()
            .split("T")[0]
        : null,
    },
    relationships: {
      location: {
        data: {
          id: safeworkinstruction.location_id,
          type: "location--location",
        },
      },
      instruction: {
        data: safeworkinstruction.instruction
          ? {
              id: safeworkinstruction.instruction.id,
              type: safeworkinstruction.instruction.type,
            }
          : null,
      },
    },
  };

  if (safeworkinstruction.id) {
    serialized.id = safeworkinstruction.id;
  }

  return serialized;
}

function deserializer(
  safeworkinstructionData: any,
  included: any,
  jsonApi: any
) {
  let instruction_data = safeworkinstructionData.relationships.instruction.data;

  let safeworkinstruction = newSafeworkinstruction(
    safeworkinstructionData.relationships.location.data.id
  );

  safeworkinstruction.id = safeworkinstructionData.id;
  safeworkinstruction.created = safeworkinstructionData.attributes.created;
  safeworkinstruction.changed = safeworkinstructionData.attributes.changed;
  safeworkinstruction.instructor_name =
    safeworkinstructionData.attributes.instructor_name;
  safeworkinstruction.attendee = safeworkinstructionData.attributes.attendee;
  safeworkinstruction.subject = safeworkinstructionData.attributes.subject;
  safeworkinstruction.instruction_date =
    safeworkinstructionData.attributes.instruction_date;

  if (instruction_data && included) {
    // instruction file linked.
    safeworkinstruction.instruction = jsonApi.deserialize.collection
      .call(jsonApi, included)
      .find(function (included: any) {
        return (
          included.type === instruction_data.type &&
          included.id === instruction_data.id
        );
      });
  }

  safeworkinstruction.initialInstruction = safeworkinstruction.instruction;

  return safeworkinstruction;
}

const jsonApiModel: JsonApiModel = {
  name: "safeworkinstruction--safeworkinstruction",
  constructor: {
    instructor_name: "",
    subject: "",
    attendee: [],
    instruction_date: "",
    location: {
      jsonApi: "hasOne",
      type: "location--location",
    },
    instruction: {
      jsonApi: "hasOne",
      type: "file--file",
    },
  },
  options: {
    collectionPath: "safeworkinstruction/safeworkinstruction",
    serializer: serializer,
    deserializer: deserializer,
  },
  includeParams: ["instruction"],
};

export default jsonApiModel;
