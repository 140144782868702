import { JsonApiModel } from "../JsonApiEntity";
import { newLocation } from "./slice";
import { Advisor } from "../advisor/slice";
import { Sector } from "../sector/slice";

function deserializer(
  locationData: any,
  included: Array<Advisor> | undefined,
  jsonApi: any
) {
  let location = newLocation();
  location.id = locationData.id;
  location.created = locationData.attributes.created;
  location.changed = locationData.attributes.changed;
  location.name = locationData.attributes.name;
  location.address = locationData.attributes.address;
  location.zipcode = locationData.attributes.zipcode;
  location.city = locationData.attributes.city;
  location.employees =
    locationData.attributes.employees === null
      ? undefined
      : locationData.attributes.employees;
  location.contact =
    locationData.attributes.contact === null
      ? undefined
      : locationData.attributes.contact;
  location.email =
    locationData.attributes.email === null
      ? undefined
      : locationData.attributes.email;
  location.telephone =
    locationData.attributes.telephone === null
      ? undefined
      : locationData.attributes.telephone;
  location.fax =
    locationData.attributes.fax === null
      ? undefined
      : locationData.attributes.fax;
  location.description =
    locationData.attributes.description === null
      ? undefined
      : locationData.attributes.description;
  location.mijnStigasApiEditLink =
    locationData.attributes.mijn_stigas_api_edit_link === null
      ? undefined
      : locationData.attributes.mijn_stigas_api_edit_link.uri;

  let advisor_relations: Array<any> =
      locationData.relationships && locationData.relationships.advisor
        ? locationData.relationships.advisor.data
        : [],
    main_sector_relation =
      locationData.relationships && locationData.relationships.main_sector
        ? locationData.relationships.main_sector.data
        : undefined,
    relevant_sector_relations: Array<any> =
      locationData.relationships && locationData.relationships.relevant_sector
        ? locationData.relationships.relevant_sector.data
        : [];

  location.advisor = [];

  if (included) {
    let included_advisors: Array<Advisor> = jsonApi.deserialize.collection
        .call(jsonApi, included, included)
        .filter(function (included: any) {
          return included.type === "advisor--advisor";
        }),
      included_sectors: Array<Sector> = jsonApi.deserialize.collection
        .call(jsonApi, included, included)
        .filter(function (included: any) {
          return included.type === "sector--sector";
        });

    advisor_relations.forEach(function (advisor_relation) {
      let advisor = included_advisors.find(function (included_advisor) {
        return included_advisor.id === advisor_relation.id;
      });

      if (advisor) {
        location.advisor.push(advisor);
      }
    });

    if (main_sector_relation) {
      location.mainSector = included_sectors.find(function (included_sector) {
        return included_sector.id === main_sector_relation.id;
      });
    }

    relevant_sector_relations.forEach(function (relevant_sector_relation) {
      let sector = included_sectors.find(function (included_sector) {
        return included_sector.id === relevant_sector_relation.id;
      });
      if (sector) {
        location.relevantSector.push(sector);
      }
    });
  }

  return location;
}

const jsonApiModel: JsonApiModel = {
  name: "location--location",
  constructor: {
    address: "",
    city: "",
    advisor: {
      jsonApi: "hasMany",
      type: "advisor--advisor",
    },
    main_sector: {
      jsonApi: "hasOne",
      type: "sector--sector",
    },
    relevant_sector: {
      jsonApi: "hasMany",
      type: "sector--sector",
    },
  },
  options: {
    collectionPath: "location/location",
    deserializer: deserializer,
  },
  includeParams: ["advisor", "advisor.photo", "main_sector", "relevant_sector"],
};

export default jsonApiModel;
