import React, { Component } from "react";
import Manager from "../../Keycloak/Manager";
import WaitDialog from "../../Component/Popup/WaitDialog";

type LoginProps = {
  redirectToPathAfterLogin?: string;
};

export default class Login extends Component<LoginProps, {}> {
  componentDidMount() {
    const keycloakManager = Manager.getInstance();
    keycloakManager.login(this.props.redirectToPathAfterLogin);
  }

  render() {
    return (
      <WaitDialog isOpened={true}>
        <p>Een ogenblik geduld aub...</p>
      </WaitDialog>
    );
  }
}
