import { JsonApiModel } from "../JsonApiEntity";
import { newWorkbook, WorkbookAction, WorkbookProgress } from "./slice";

function workbookProgressDeserializer(workbookProgressData: any) {
  let workbookProgress: WorkbookProgress = {
    name: workbookProgressData.name,
    title: workbookProgressData.title,
    all: parseInt(workbookProgressData.all, 10),
    processed: parseInt(workbookProgressData.processed, 10),
    percentage: parseInt(workbookProgressData.percentage, 10),
    complete: workbookProgressData.complete === "1",
    mijnStigasApiEditLink: workbookProgressData.mijn_stigas_api_edit_link,
  };

  return workbookProgress;
}

function workbookActionDeserializer(workbookActionData: any) {
  let workbookAction: WorkbookAction = {
    id: parseInt(workbookActionData.id, 10),
    text: workbookActionData.text,
    riskClassification: workbookActionData.risk_classification,
    startDate: workbookActionData.start_date
      ? workbookActionData.start_date
      : null,
    endDate: workbookActionData.end_date ? workbookActionData.end_date : null,
    solved: workbookActionData.solved === "1",
    solvedDate: workbookActionData.solved_date
      ? workbookActionData.solved_date
      : null,
    mijnStigasApiEditLink: workbookActionData.mijn_stigas_api_edit_link,
  };

  return workbookAction;
}

function deserializer(workbookData: any, included: any, jsonApi: any) {
  let workbook = newWorkbook(workbookData.relationships.location.data.id);

  workbook.id = workbookData.id;
  workbook.created = workbookData.attributes.created;
  workbook.changed = workbookData.attributes.changed;
  workbook.name = workbookData.attributes.name;

  workbookData.attributes.progress.forEach(function (progress: any) {
    workbook.progress.push(workbookProgressDeserializer(progress));
  });

  workbookData.attributes.action.forEach(function (action: any) {
    workbook.action.push(workbookActionDeserializer(action));
  });

  workbook.lastActivity =
    workbookData.attributes.last_activity !== null
      ? workbookData.attributes.last_activity
      : undefined;

  return workbook;
}

const jsonApiModel: JsonApiModel = {
  name: "workbook--workbook",
  constructor: {
    name: "",
    location: {
      jsonApi: "hasOne",
      type: "location--location",
    },
  },
  options: {
    collectionPath: "workbook/workbook",
    deserializer: deserializer,
  },
  includeParams: [],
};

export default jsonApiModel;
