import { JsonApiModel } from "../JsonApiEntity";
import { newFile } from "../file";

function deserializer(fileData: any, included: any, jsonApi: any) {
  let file = newFile();

  file.id = fileData.id;
  file.created = fileData.attributes.created;
  file.changed = fileData.attributes.changed;
  file.filename = fileData.attributes.filename;
  file.filemime = fileData.attributes.filemime;
  file.filesize = fileData.attributes.filesize;

  if (fileData.attributes.uri.url.indexOf("/") === 0) {
    // Relative url, suffix it with the protocol, domain and port of
    // fileData.links.self.href.
    let self_href = new URL(fileData.links.self.href);
    file.url = self_href.origin + fileData.attributes.uri.url;
  } else {
    file.url = window.location.origin + fileData.attributes.uri.url;
  }

  return file;
}

const jsonApiModel: JsonApiModel = {
  name: "file--file",
  constructor: {
    filename: "",
    filemime: "",
    filesize: "",
    uri: {},
  },
  options: {
    collectionPath: "file/file",
    deserializer: deserializer,
  },
};

export default jsonApiModel;
