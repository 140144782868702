import {
  createAsyncThunk,
  createSlice,
  EntityState,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import Client from "../../../Client/Client";
import { JsonApiEntity } from "../JsonApiEntity";
import { Location } from "../location/slice";
import { File as ClientFile } from "../file";

export interface Bhvperson extends JsonApiEntity {
  name?: string;
  course_date?: string;
  course_expires_date?: string;
  location_id: string;
  certificate?: ClientFile;
  localCertificateFile?: File;
  initialCertificate?: ClientFile;
}

export function newBhvperson(location_id: string): Bhvperson {
  return {
    id: "",
    type: "bhvperson--bhvperson",
    created: "",
    changed: "",
    name: undefined,
    course_date: undefined,
    course_expires_date: undefined,
    location_id: location_id,
    certificate: undefined,
    localCertificateFile: undefined,
  };
}

export enum FetchBhvpersonsStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export const bhvpersonEntityAdapter = createEntityAdapter<Bhvperson>();

export interface BhvpersonState extends EntityState<Bhvperson> {
  fetchBhvpersonsStatus: FetchBhvpersonsStatus;
  error: string | null;
  waitingForClientUpsertCounter: number;
}

export const initialState: BhvpersonState = bhvpersonEntityAdapter.getInitialState(
  {
    fetchBhvpersonsStatus: FetchBhvpersonsStatus.Idle,
    error: null,
    waitingForClientUpsertCounter: 0,
  }
);

export const fetchBhvpersons = createAsyncThunk<
  Array<object>,
  Location,
  {
    extra: {
      client: Client;
    };
  }
>("bhvperson/fetchBhvpersons", async function (arg: Location, thunkAPI) {
  const results = await thunkAPI.extra.client.findAllForLocation(
    arg,
    "bhvperson--bhvperson"
  );
  return results.data;
});

export const createBhvperson = createAsyncThunk<
  Bhvperson,
  Bhvperson,
  {
    extra: {
      client: Client;
    };
  }
>("bhvperson/createBhvperson", async function (arg: Bhvperson, thunkAPI) {
  let params: any = {},
    createdCertificateFile: ClientFile | undefined;

  if (arg.localCertificateFile) {
    const file_results = await thunkAPI.extra.client.createFile(
      arg.type,
      "certificate",
      arg.localCertificateFile
    );

    if (file_results.status !== 201) {
      throw Error(
        "Certificate upload failed with error code " + file_results.status
      );
    }

    createdCertificateFile = thunkAPI.extra.client.jsonApi.deserialize.resource.call(
      thunkAPI.extra.client.jsonApi,
      file_results.data.data
    );

    if (createdCertificateFile) {
      arg.certificate = createdCertificateFile;
    }
  }

  const results = await thunkAPI.extra.client.create(arg, params);

  if (createdCertificateFile) {
    // The API doesn't return the linked certificate after creation.
    results.data.certificate = createdCertificateFile;
  }

  return results.data;
});

export const deleteBhvperson = createAsyncThunk<
  string,
  Bhvperson,
  {
    extra: {
      client: Client;
    };
  }
>("bhvperson/deleteBhvperson", async function (arg: Bhvperson, thunkAPI) {
  await thunkAPI.extra.client.delete(arg);

  return arg.id;
});

export const updateBhvperson = createAsyncThunk<
  Bhvperson,
  Bhvperson,
  {
    extra: {
      client: Client;
    };
  }
>("bhvperson/updateBhvperson", async function (arg: Bhvperson, thunkAPI) {
  const results = await thunkAPI.extra.client.update(arg);

  if (arg.localCertificateFile) {
    const file_results = await thunkAPI.extra.client.updateFile(
      arg,
      "certificate",
      arg.localCertificateFile
    );

    if (file_results.status !== 200) {
      throw Error(
        "Certificate upload failed with error code " + file_results.status
      );
    }

    let createdCertificateFile:
      | ClientFile
      | undefined = thunkAPI.extra.client.jsonApi.deserialize.resource.call(
      thunkAPI.extra.client.jsonApi,
      file_results.data.data
    );

    if (createdCertificateFile) {
      results.data.certificate = createdCertificateFile;
      arg.certificate = createdCertificateFile;
    }
  } else {
    results.data.certificate = arg.certificate;

    if (
      results.data.certificate === undefined &&
      arg.initialCertificate !== undefined
    ) {
      // Remove current certificate.
      const file_results = await thunkAPI.extra.client.deleteFile(
        arg.initialCertificate
      );

      if (file_results.status !== 204) {
        throw Error(
          "Certificate delete failed with error code " + file_results.status
        );
      }

      arg.initialCertificate = undefined;
    }
  }

  return results.data;
});

function increaseWaitingForClientUpsertCounter(
  state: BhvpersonState,
  action: any
) {
  state.waitingForClientUpsertCounter++;
}

function decreaseWaitingForClientUpsertCounter(
  state: BhvpersonState,
  action: any
) {
  state.waitingForClientUpsertCounter--;
}

const slice = createSlice({
  name: "bhvperson",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchBhvpersons.pending.toString()]: function (
      state: BhvpersonState,
      action: any
    ) {
      state.fetchBhvpersonsStatus = FetchBhvpersonsStatus.Loading;
      bhvpersonEntityAdapter.removeAll(state);
    },
    [fetchBhvpersons.rejected.toString()]: function (
      state: BhvpersonState,
      action: any
    ) {
      state.fetchBhvpersonsStatus = FetchBhvpersonsStatus.Failed;
      state.error = action.error.message;
    },
    [fetchBhvpersons.fulfilled.toString()]: function (
      state: BhvpersonState,
      action: any
    ) {
      state.fetchBhvpersonsStatus = FetchBhvpersonsStatus.Succeeded;
      bhvpersonEntityAdapter.upsertMany(state, action.payload);
    },

    [createBhvperson.pending.toString()]: increaseWaitingForClientUpsertCounter,
    [createBhvperson.rejected.toString()]: decreaseWaitingForClientUpsertCounter,
    [createBhvperson.fulfilled.toString()]: function (
      state: BhvpersonState,
      action: any
    ) {
      bhvpersonEntityAdapter.addOne(state, action.payload);
      decreaseWaitingForClientUpsertCounter(state, action);
    },

    [deleteBhvperson.fulfilled.toString()]: function (
      state: BhvpersonState,
      action: any
    ) {
      bhvpersonEntityAdapter.removeOne(state, action.payload);
    },

    [updateBhvperson.pending.toString()]: increaseWaitingForClientUpsertCounter,
    [updateBhvperson.rejected.toString()]: decreaseWaitingForClientUpsertCounter,
    [updateBhvperson.fulfilled.toString()]: function (
      state: BhvpersonState,
      action: any
    ) {
      bhvpersonEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      decreaseWaitingForClientUpsertCounter(state, action);
    },
  },
});

export function selectAllBhvpersonsByLocation(
  state: any,
  location: Location
): Array<Bhvperson> {
  return bhvpersonEntityAdapter
    .getSelectors()
    .selectAll(state.bhvperson)
    .filter(function (bhvperson: Bhvperson) {
      return bhvperson.location_id === location.id;
    });
}

export default slice.reducer;
