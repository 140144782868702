import { createSlice } from "@reduxjs/toolkit";
import { JsonApiEntity } from "../JsonApiEntity";

export interface Sector extends JsonApiEntity {
  sector_id?: string;
  name?: string;
}

export function newSector(): Sector {
  return {
    id: "",
    type: "sector--sector",
    created: "",
    changed: "",
    sector_id: undefined,
    name: undefined,
  };
}

interface SectorState {
  sector: Array<Sector>;
}

export const initialState: SectorState = {
  sector: [],
};

const slice = createSlice({
  name: "sector",
  initialState: initialState,
  reducers: {},
});

export default slice.reducer;
