import React, { Component } from "react";
import { Advisor } from "../../Redux/reducer/advisor/slice";
import ContactBlock from "./ContactBlock";

interface AdvisorContactBlockProps {
  advisor?: Advisor;
  topic?: string;
  size?: string;
}

export default class AdvisorContactBlock extends Component<
  AdvisorContactBlockProps,
  any
> {
  render() {
    if (!this.props.advisor) {
      // Generic advisor.
      return (
        <ContactBlock
          title="Hulp nodig van een adviseur?"
          image_url="/img/advisor/peter.bredius.jpg"
          size={this.props.size}
        >
          <p>
            Onze experts staan klaar om jou te helpen
            {this.props.topic ? " bij " + this.props.topic : null}.
          </p>
          <p>
            Bel <a href="tel:0850440700">085 - 044 07 00</a> (optie 1) of mail
            naar <a href="mailto:info@stigas.nl">info@stigas.nl</a>.
          </p>
          <p>Binnen 2 dagen neemt een adviseur contact met je op.</p>
        </ContactBlock>
      );
    }

    let photo_url = this.props.advisor.photo
      ? this.props.advisor.photo.url
      : undefined;

    if (!photo_url && this.props.advisor.email) {
      let mail = this.props.advisor.email.split("@");
      photo_url = "/img/advisor/" + mail[0].toLowerCase() + ".jpg";
    }

    return (
      <ContactBlock
        title="Hulp nodig van een adviseur?"
        image_url={photo_url}
        size={this.props.size}
      >
        <p>
          {this.props.advisor.name
            ? this.props.advisor.name + " staat "
            : "Onze experts staan "}
          klaar om jou te helpen
          {this.props.topic ? " bij " + this.props.topic : null}.
        </p>
        <p>
          {this.props.advisor.telephone ? (
            <span>
              Bel{" "}
              <a href={"tel" + this.props.advisor.telephone}>
                {this.props.advisor.telephone}
              </a>{" "}
            </span>
          ) : (
            <span>
              Bel <a href="tel:0850440700">085 - 044 07 00</a> (optie 1){" "}
            </span>
          )}
          of mail naar{" "}
          {this.props.advisor.email ? (
            <a href={"mailto:" + this.props.advisor.email}>
              {this.props.advisor.email}
            </a>
          ) : (
            <a href="mailto:info@stigas.nl">info@stigas.nl</a>
          )}
          .
        </p>
        <p>
          Binnen 2 dagen neemt{" "}
          {this.props.advisor.name ? this.props.advisor.name : "een adviseur"}{" "}
          contact met je op.
        </p>
      </ContactBlock>
    );
  }
}
