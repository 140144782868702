import React, { Component } from "react";
import "./Popup.scss";

type WaitDialogProps = {
  title?: string;
  isOpened: boolean;
};

export default class WaitDialog extends Component<WaitDialogProps, {}> {
  render() {
    if (this.props.isOpened) {
      return (
        <div className="popup-container WaitDialog">
          <div className="popup-backdrop"></div>
          <div className="popup">
            <h2 className="popup-title">
              {this.props.title ? this.props.title : "Een ogenblik geduld..."}
            </h2>
            <div className="wait-message">
              <div className="wait-animation"></div>
              {this.props.children}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
